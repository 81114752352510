"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toString = exports.parse = exports.WitnessScope = void 0;
/**
 * Used to constrain witness to specified scope
 * The execution in vm of a script is kind of opaque, user could add scope on his signature to avoid abuse of signature
 */
var WitnessScope;
(function (WitnessScope) {
    WitnessScope[WitnessScope["None"] = 0] = "None";
    /**
     * CalledByEntry means that this condition must hold: EntryScriptHash == CallingScriptHash
     * No params is needed, as the witness/permission/signature given on first invocation will automatically expire if entering deeper internal invokes
     * This can be default safe choice for native NEO/GAS (previously used on Neo 2 as "attach" mode)
     */
    WitnessScope[WitnessScope["CalledByEntry"] = 1] = "CalledByEntry";
    /**
     * Custom hash for contract-specific
     */
    WitnessScope[WitnessScope["CustomContracts"] = 16] = "CustomContracts";
    /**
     * Custom pubkey for group members, group can be found in contract manifest
     */
    WitnessScope[WitnessScope["CustomGroups"] = 32] = "CustomGroups";
    /**
     * Custom rules for witness to adhere by.
     */
    WitnessScope[WitnessScope["WitnessRules"] = 64] = "WitnessRules";
    /**
     * Global allows this witness in all contexts (default Neo2 behavior)
     * This cannot be combined with other flags
     */
    WitnessScope[WitnessScope["Global"] = 128] = "Global";
})(WitnessScope = exports.WitnessScope || (exports.WitnessScope = {}));
function parse(stringFlags) {
    const flags = stringFlags.split(/\,/g);
    return flags.reduce((p, c) => p | WitnessScope[c.trim()], WitnessScope.None);
}
exports.parse = parse;
function getEnums() {
    return Object.values(WitnessScope).filter((k) => typeof k === "number");
}
function toString(flags) {
    if (flags === WitnessScope.None) {
        return "None";
    }
    return getEnums()
        .filter((f) => flags & f)
        .map((f) => WitnessScope[f])
        .join(",");
}
exports.toString = toString;
