"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionAttribute = exports.toTxAttrUsage = void 0;
const u_1 = require("../../u");
const txAttrUsage_1 = require("./txAttrUsage");
const maxTransactionAttributeSize = 252;
function toTxAttrUsage(type) {
    if (typeof type === "string") {
        if (type in txAttrUsage_1.TxAttrUsage) {
            return txAttrUsage_1.TxAttrUsage[type];
        }
        throw new Error(`${type} not found in TxAttrUsage!`);
    }
    else if (typeof type === "number") {
        if (txAttrUsage_1.TxAttrUsage.Url !== type) {
            throw new Error(`${type} not found in TxAttrUsage!`);
        }
    }
    return type;
}
exports.toTxAttrUsage = toTxAttrUsage;
/**
 * An attribute that is used to decorate the transaction.
 * Used for appending additional information to the transaction.
 *
 * For example, a remark is attached as an attribute.
 */
class TransactionAttribute {
    constructor(obj = {}) {
        if (!obj.usage || !obj.data) {
            throw new Error("TransactionAttribute requires usage and data fields");
        }
        const { usage, data } = obj;
        this.usage = toTxAttrUsage(usage);
        this.data = u_1.HexString.fromHex(data);
    }
    static deserialize(hex) {
        const ss = new u_1.StringStream(hex);
        return this.fromStream(ss);
    }
    static fromJson(input) {
        return new TransactionAttribute({
            usage: toTxAttrUsage(input.usage),
            data: u_1.HexString.fromBase64(input.data, true),
        });
    }
    static fromStream(ss) {
        const usage = parseInt(ss.read(1), 16);
        const data = ss.readVarBytes();
        if (data.length > maxTransactionAttributeSize * 2) {
            throw new Error("Data too big! Only 252 bytes allowed in data");
        }
        return new TransactionAttribute({ usage, data });
    }
    get size() {
        return (1 + (0, u_1.num2VarInt)(this.data.byteLength).length / 2 + this.data.byteLength);
    }
    /**
     * Constructs a Url Attribute
     * @param url - URL string in ASCII
     */
    static Url(url) {
        return new TransactionAttribute({
            usage: txAttrUsage_1.TxAttrUsage.Url,
            data: u_1.HexString.fromAscii(url),
        });
    }
    get [Symbol.toStringTag]() {
        return "TransactionAttribute";
    }
    serialize() {
        if (this.data.length > maxTransactionAttributeSize) {
            throw new Error(`Data size too big!`);
        }
        let out = (0, u_1.num2hexstring)(this.usage);
        out += (0, u_1.num2VarInt)(this.data.byteLength);
        out += this.data;
        return out;
    }
    export() {
        return {
            usage: this.usage,
            data: this.data.toBigEndian(),
        };
    }
    toJson() {
        return {
            usage: txAttrUsage_1.TxAttrUsage[this.usage],
            data: this.data.toBase64(true),
        };
    }
    equals(other) {
        var _a, _b;
        return (this.usage === toTxAttrUsage((_a = other.usage) !== null && _a !== void 0 ? _a : 0) &&
            this.data.equals((_b = other.data) !== null && _b !== void 0 ? _b : ""));
    }
}
exports.TransactionAttribute = TransactionAttribute;
exports.default = TransactionAttribute;
