"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateExecutionFee = void 0;
const u_1 = require("../u");
const InteropServiceCode_1 = require("./InteropServiceCode");
const InteropServicePrices_1 = require("./InteropServicePrices");
const OpCode_1 = require("./OpCode");
const OpCodePrices_1 = require("./OpCodePrices");
const OpToken_1 = require("./OpToken");
/**
 * Estimate the cost of running an arbitrary script in the VM.
 * This only supports running non APPCALL scripts.
 * Mainly used to calculate network fees.
 */
function calculateExecutionFee(script, executionFeeFactor) {
    const opTokens = typeof script === "string" ? OpToken_1.OpToken.fromScript(script) : script;
    const factor = typeof executionFeeFactor === "number"
        ? u_1.BigInteger.fromNumber(executionFeeFactor)
        : executionFeeFactor;
    return opTokens
        .map((token, i) => {
        if (token.code === OpCode_1.OpCode.SYSCALL &&
            token.params &&
            token.params.length === 8) {
            const interopCode = token.params;
            if (interopCode === InteropServiceCode_1.InteropServiceCode.SYSTEM_CRYPTO_CHECKMULTISIG) {
                const threshold = extractThresholdForMultiSig(opTokens, i);
                return u_1.BigInteger.fromNumber(OpCodePrices_1.OpCodePrices[token.code]).add(u_1.BigInteger.fromNumber((0, InteropServicePrices_1.getInteropServicePrice)(InteropServiceCode_1.InteropServiceCode.SYSTEM_CRYPTO_CHECKSIG))
                    .mul(threshold)
                    .mul(factor));
            }
            return u_1.BigInteger.fromNumber(OpCodePrices_1.OpCodePrices[token.code])
                .add(u_1.BigInteger.fromNumber((0, InteropServicePrices_1.getInteropServicePrice)(interopCode)))
                .mul(factor);
        }
        else if (OpCode_1.OpCode[token.code] !== null) {
            return u_1.BigInteger.fromNumber(OpCodePrices_1.OpCodePrices[token.code]).mul(factor);
        }
        throw new Error(`OpCode ${token.code} not found!`);
    })
        .reduce((a, b) => a.add(b), u_1.BigInteger.fromNumber(0));
}
exports.calculateExecutionFee = calculateExecutionFee;
function extractThresholdForMultiSig(tokens, sysCallIndex) {
    // We know that for a multisig of threshold t & no.of keys k the order is:
    // t, key1, key2, ...keyk, k, SYSCALL
    const tokenK = tokens[sysCallIndex - 1];
    const k = OpToken_1.OpToken.parseInt(tokenK);
    const tokenT = tokens[sysCallIndex - 1 - k - 1];
    return OpToken_1.OpToken.parseInt(tokenT);
}
