import React from "react"

import Global from "../../Global"
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import AccountContext from "../../contexts/AccountContext";

export default function Nep11Detail(props) {
    const navigate = useNavigate();

    let { contract, id } = useParams();

    const { account, chestKey } = React.useContext(AccountContext)


    const [nep11Data,setNep11Data] = React.useState(null)
    const Assets = Global.Assets;
    const SocialIcons = Global.SocialIcons;


    const back = () => {
        navigate(-1)
    }


    const fetchNEP11Data = (contract, tokenId) => {
        const url = `https://api.ghostmarket.io/api/v1/metadata?chain=n3&contract=${contract}&token_id=${tokenId}`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setNep11Data(data)
            });
    }


    React.useEffect(()=> {
        fetchNEP11Data(contract,id)
    },[])

    return (
        <div className="absolute inset-0">
            <div className="p-3 container mx-auto relative flex flex-col h-full ">

                <div className="flex items-center">

                   <div></div>
                    <div className="ml-auto">

                    </div>
                </div>
                <div>
                    <button onClick={back} className="text-sm font-bold uppercase flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        <span>Back</span>
                    </button>
                </div>

                <div className="mt-6">

                    <div className="flex">
                        <div className="w-24 h-24 rounded-lg overflow-hidden">
                            <img className="" src={Assets[contract].icon_url} />
                        </div>
                        <div className="ml-3 flex flex-col">
                            <p className="text-2xl font-bold">{Assets[contract].name}</p>
                            <div className="mt-1">
                                <p className="inline-block px-3 py-1 bg-slate-200 font-bold rounded-full">{Assets[contract].symbol}</p>
                            </div>
                          

                        </div>
                    </div>
                    {
                        nep11Data !== null ?
                        <div className="mt-6">
                        <p>{nep11Data.description}</p>

                        <a target="_blank" href={`https://dora.coz.io/nft/neo3/mainnet/${contract}/${id}`} className="mt-1 text-primary font-bold rounded-full inline-flex items-center">
                                <p className="mr-2">View on Dora</p>
                                <svg className="ml-auto fill-current text-primary" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11.88 9.54L6.92 14.5L5.51 13.09L10.47 8.13L8.34 6L13.99 6.01L14 11.66L11.88 9.54Z" />
                                </svg>
                            </a>
                    </div>
                    :null
                    }
                   
                    <div className="mt-6">
                        <p className="font-semibold">{Assets[contract].description}</p>
                    </div>
                    <div className="mt-6">
                        <p className="text-sm uppercase font-bold mb-1">SOCIAL LINKS</p>
                        <div className="flex gap-1">
                            {
                                Assets[contract].socials.map((item) => (
                                    <div key={item.id} className="bg-secondary text-white w-6 h-6 p-1">
                                        <a target="_blank" href={item.url}>
                                            <img src={SocialIcons[item.id]} />
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mt-6">
                        <p className="text-sm uppercase font-bold mb-1">WEBSITE</p>
                        <a target="_blank" href={Assets[contract].website_url}>
                        <div className="bg-secondary pl-4 py-1 text-white font-bold rounded-full inline-flex items-center">
                            <p className="mr-2">{Assets[contract].website_url}</p>
                            <svg className="ml-auto mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11.88 9.54L6.92 14.5L5.51 13.09L10.47 8.13L8.34 6L13.99 6.01L14 11.66L11.88 9.54Z" fill="white" />
                            </svg>
                        </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}