import React from "react"
import { Link } from "react-router-dom"
import AccountContext from "../../contexts/AccountContext"
import Header from "../Components/Header";
// import WalletConnectContext from "../../contexts/WalletConnectContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import EventContext from "../../contexts/EventContext";
import {useWalletConnect} from "../../contexts/WalletConnectContext";
export default function Home(props) {
    const {sessions} = props
    const navigate = useNavigate();
    const { account, chestKey, isEligible, puppetTokenId, balances } = React.useContext(AccountContext)
    // const { wcClient, isConnected, session, proposal, chestMetadata } = React.useContext(WalletConnectContext)
    const {event} = React.useContext(EventContext)
    const walletConnectCtx = useWalletConnect()

    return (
        <div className="absolute inset-0 text-secondary">

            <div className="p-3 container mx-auto relative flex flex-col items-center justify-center h-full ">
                <div className="mt-6">
                    <Header></Header>
                </div>
                <div className="mx-auto shadow-xl rounded-xl p-6 mt-6 mb-6">
                    <svg className="w-20 h-20" viewBox="0 0 106 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 20.5092V98.7639L50.922 117V38.0963L105.981 17.8556L56.0136 0L0 20.5092Z" fill="#00E599" />
                        <path d="M55.0651 40.347V83.1558L106 101.36L105.978 21.5642L55.0651 40.347Z" fill="#00AF92" />
                    </svg>
                </div>
                {
                    account === null ?
                        null
                        :
                        <div className="font-bold text-sm text-center p-2 px-4 bg-gray-100 rounded-full">{account.address}</div>
                }
                {
                    account === null ?
                        <div className="p-6">
                            <p>Get paper wallet at NEO Booth</p>
                            {event.prize_description}
                        </div>
                        :
                        <div className="p-6 text-center">
                            {
                            balances &&  balances.length === 0 ? null 
                            :
                            <div>
                            <p>{event.description}</p> 
                            <p className="font-semibold mt-6">{event.prize_description}</p>
                            </div>
                            }
                            
                        </div>
                }
                

                {
                    account === null ?
                        <div className="">

                        </div>
                        :
                        <div className="mt-auto p-3 w-full">
                            {
                                walletConnectCtx.initialized === true ?
                                    <Link to={"/asset"}>
                                        <button className="bg-primary w-full py-2 font-semibold text-white uppercase">Next</button>
                                    </Link>
                                    :
                                    balances && balances.length === 0 ? null
                                    :
                                    <div className="text-center">
                                        <p>Loading...</p>
                                        <p className="text-xs">Please wait a moment</p>
                                    </div>
                            }

                        </div>
                }

            </div>

        </div>
    )
}