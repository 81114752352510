"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallFlags = void 0;
var CallFlags;
(function (CallFlags) {
    CallFlags[CallFlags["None"] = 0] = "None";
    CallFlags[CallFlags["ReadStates"] = 1] = "ReadStates";
    CallFlags[CallFlags["WriteStates"] = 2] = "WriteStates";
    CallFlags[CallFlags["AllowCall"] = 4] = "AllowCall";
    CallFlags[CallFlags["AllowNotify"] = 8] = "AllowNotify";
    CallFlags[CallFlags["States"] = 3] = "States";
    CallFlags[CallFlags["ReadOnly"] = 5] = "ReadOnly";
    CallFlags[CallFlags["All"] = 15] = "All";
})(CallFlags = exports.CallFlags || (exports.CallFlags = {}));
exports.default = CallFlags;
