"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenTrackerRpcClient = exports.TokenTrackerRpcMixin = void 0;
const Query_1 = require("../Query");
const RpcDispatcher_1 = require("./RpcDispatcher");
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
function TokenTrackerRpcMixin(base) {
    return class TokenTrackerRpcInterface extends base {
        async getNep17Transfers(accountIdentifier, startTime, endTime) {
            return this.execute(Query_1.Query.getNep17Transfers(accountIdentifier, startTime, endTime));
        }
        async getNep17Balances(accountIdentifier) {
            return this.execute(Query_1.Query.getNep17Balances(accountIdentifier));
        }
        async getNep11Transfers(accountIdentifier, startTime, endTime) {
            return this.execute(Query_1.Query.getNep11Transfers(accountIdentifier, startTime, endTime));
        }
        async getNep11Balances(accountIdentifier) {
            return this.execute(Query_1.Query.getNep11Balances(accountIdentifier));
        }
    };
}
exports.TokenTrackerRpcMixin = TokenTrackerRpcMixin;
class TokenTrackerRpcClient extends TokenTrackerRpcMixin(RpcDispatcher_1.RpcDispatcher) {
    get [Symbol.toStringTag]() {
        return `TokenTrackerRpcClient(${this.url})`;
    }
}
exports.TokenTrackerRpcClient = TokenTrackerRpcClient;
