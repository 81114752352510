"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateSignature = exports.verify = exports.sign = void 0;
const u_1 = require("../u");
const core_1 = require("./core");
const verify_1 = require("./verify");
const curve = (0, u_1.getCurve)(u_1.EllipticCurvePreset.SECP256R1);
/**
 * Generates a ECDSA signature from a hexstring using the given private key.
 * @param hex - hexstring to hash.
 * @param privateKey - hexstring or WIF format.
 * @param k - optional nonce for generating a signature. Providing this value will cause the signature generated to be deterministic.
 * @returns a 64 byte hexstring made from (r+s)
 */
function sign(hex, privateKey, k) {
    if ((0, verify_1.isWIF)(privateKey)) {
        privateKey = (0, core_1.getPrivateKeyFromWIF)(privateKey);
    }
    const msgHash = (0, u_1.sha256)(hex);
    const sig = curve.sign(msgHash, privateKey, k);
    return sig.r + sig.s;
}
exports.sign = sign;
/**
 * Verifies that the message, signature and signing key matches.
 * @param hex - message that was signed.
 * @param sig - ECDSA signature in the form of a 64 byte hexstring (r+s).
 * @param publicKey - encoded/unencoded public key of the signing key.
 */
function verify(hex, sig, publicKey) {
    if (!(0, verify_1.isPublicKey)(publicKey)) {
        throw new Error("Invalid public key");
    }
    if (!(0, verify_1.isPublicKey)(publicKey, true)) {
        publicKey = (0, core_1.getPublicKeyUnencoded)(publicKey);
    }
    const ecdsaSignature = {
        r: sig.substr(0, 64),
        s: sig.substr(64, 64),
    };
    const messageHash = (0, u_1.sha256)(hex);
    return curve.verify(messageHash, ecdsaSignature, publicKey);
}
exports.verify = verify;
/**
 * Generates a signature of the transaction based on given private key.
 * @param tx - serialized unsigned transaction
 * @param privateKey - private Key
 * @returns Signature. Does not include tx.
 *
 * @deprecated please use sign(tx, privateKey).
 */
function generateSignature(tx, privateKey) {
    return sign(tx, privateKey);
}
exports.generateSignature = generateSignature;
