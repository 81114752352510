"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEnum = void 0;
/**
 * Parses a string, number or enum to an enum.
 */
function parseEnum(input, enumType) {
    if (typeof input === "string") {
        if (input in enumType) {
            return enumType[input];
        }
        throw new Error(`${input} not found in enum!`);
    }
    return input;
}
exports.parseEnum = parseEnum;
