"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchQuery = void 0;
/**
 * Helper class that maintains the types in a list of Queries.
 * This allows the RpcDispatcher to return correctly typed responses.
 */
class BatchQuery {
    constructor(q) {
        this.queries = [q];
    }
    add(q) {
        this.queries.push(q);
        return this;
    }
    static of(q) {
        return new BatchQuery(q);
    }
}
exports.BatchQuery = BatchQuery;
