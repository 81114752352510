"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.num2VarInt = exports.num2hexstring = exports.int2hex = exports.hexstring2str = exports.str2hexstring = exports.ab2hexstring = exports.hexstring2ab = exports.str2ab = exports.ab2str = void 0;
const basic_1 = require("./basic");
/**
 * Converts an ArrayBuffer to an ASCII string.
 */
function ab2str(buf) {
    return String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)));
}
exports.ab2str = ab2str;
/**
 * Converts an ASCII string into an arrayBuffer.
 */
function str2ab(str) {
    if (typeof str !== "string") {
        throw new Error(`str2ab expected a string but got ${typeof str} instead.`);
    }
    const result = new Uint8Array(str.length);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        result[i] = str.charCodeAt(i);
    }
    return result;
}
exports.str2ab = str2ab;
/**
 * Converts a hexstring into an arrayBuffer.
 */
function hexstring2ab(str) {
    (0, basic_1.ensureHex)(str);
    if (!str.length) {
        return new Uint8Array(0);
    }
    const iters = str.length / 2;
    const result = new Uint8Array(iters);
    for (let i = 0; i < iters; i++) {
        result[i] = parseInt(str.substring(0, 2), 16);
        str = str.substring(2);
    }
    return result;
}
exports.hexstring2ab = hexstring2ab;
/**
 * Converts an arraybuffer to hexstring.
 */
function ab2hexstring(arr) {
    if (typeof arr !== "object") {
        throw new Error(`ab2hexstring expects an array. Input was ${arr}`);
    }
    let result = "";
    const intArray = new Uint8Array(arr);
    for (const i of intArray) {
        let str = i.toString(16);
        str = str.length === 0 ? "00" : str.length === 1 ? "0" + str : str;
        result += str;
    }
    return result;
}
exports.ab2hexstring = ab2hexstring;
/**
 * Converts an ascii string to hexstring.
 */
function str2hexstring(str) {
    return ab2hexstring(str2ab(str));
}
exports.str2hexstring = str2hexstring;
/**
 * Converts a hexstring to ascii string.
 */
function hexstring2str(hexstring) {
    return ab2str(hexstring2ab(hexstring));
}
exports.hexstring2str = hexstring2str;
/**
 * convert an integer to big endian hex and add leading zeros.
 */
function int2hex(num) {
    if (typeof num !== "number") {
        throw new Error(`int2hex expected a number but got ${typeof num} instead.`);
    }
    const h = num.toString(16);
    return h.length % 2 ? "0" + h : h;
}
exports.int2hex = int2hex;
/**
 * Converts a number to a big endian hexstring of a suitable size, optionally little endian
 * @param num - a positive integer.
 * @param size - the required size in bytes, eg 1 for Uint8, 2 for Uint16. Defaults to 1.
 * @param littleEndian - encode the hex in little endian form
 */
function num2hexstring(num, size = 1, littleEndian = false) {
    if (typeof num !== "number") {
        throw new Error(`num2hexstring expected a number but got ${typeof num} instead.`);
    }
    if (num < 0) {
        throw new RangeError(`num2hexstring expected a positive integer but got ${num} instead.`);
    }
    if (size % 1 !== 0) {
        throw new Error(`num2hexstring expected a positive integer but got ${num} instead.`);
    }
    if (!Number.isSafeInteger(num)) {
        throw new RangeError(`num2hexstring expected a safe integer but got ${num} instead.`);
    }
    size = size * 2;
    let hexstring = num.toString(16);
    hexstring =
        hexstring.length % size === 0
            ? hexstring
            : ("0".repeat(size) + hexstring).substring(hexstring.length);
    if (littleEndian) {
        hexstring = (0, basic_1.reverseHex)(hexstring);
    }
    return hexstring;
}
exports.num2hexstring = num2hexstring;
/**
 * Converts a number to a variable length Int. Used for array length header
 */
function num2VarInt(num) {
    if (num < 0xfd) {
        return num2hexstring(num);
    }
    else if (num <= 0xffff) {
        // uint16
        return "fd" + num2hexstring(num, 2, true);
    }
    else if (num <= 0xffffffff) {
        // uint32
        return "fe" + num2hexstring(num, 4, true);
    }
    else {
        // uint64
        return "ff" + num2hexstring(num, 8, true);
    }
}
exports.num2VarInt = num2VarInt;
