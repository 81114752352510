"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _HexString_value;
Object.defineProperty(exports, "__esModule", { value: true });
exports.HexString = void 0;
const basic_1 = require("./basic");
const misc_1 = require("./misc");
const convert_1 = require("./convert");
class HexString {
    /**
     * Initiate a HexString
     * @param value - a string that contains only [1-9a-f]. Can be prefixed with 0x.
     * @param littleEndian - indicate whether value is little endian or not. default to be false.
     */
    constructor(value, littleEndian = false) {
        /**
         * Will store value in big endian
         */
        _HexString_value.set(this, void 0);
        if (value.startsWith("0x")) {
            value = value.slice(2);
        }
        this.assert(value);
        __classPrivateFieldSet(this, _HexString_value, littleEndian ? (0, basic_1.reverseHex)(value) : value, "f");
    }
    get length() {
        return __classPrivateFieldGet(this, _HexString_value, "f").length;
    }
    get byteLength() {
        return __classPrivateFieldGet(this, _HexString_value, "f").length / 2;
    }
    assert(value) {
        (0, basic_1.ensureHex)(value);
    }
    toString() {
        return __classPrivateFieldGet(this, _HexString_value, "f");
    }
    /**
     * Export as big endian string
     */
    toBigEndian() {
        return __classPrivateFieldGet(this, _HexString_value, "f");
    }
    /**
     * Export as little endian string
     */
    toLittleEndian() {
        return (0, basic_1.reverseHex)(__classPrivateFieldGet(this, _HexString_value, "f"));
    }
    /**
     * Returns a new HexString with internal value reversed hex.
     */
    reversed() {
        return new HexString((0, basic_1.reverseHex)(__classPrivateFieldGet(this, _HexString_value, "f")));
    }
    /**
     * Judge if 2 HexString are equal
     */
    equals(other) {
        if (typeof other === "string") {
            return this.toBigEndian() === HexString.fromHex(other).toBigEndian();
        }
        return this.toBigEndian() === other.toBigEndian();
    }
    /**
     * XOR with another HexString to get a new one.
     */
    xor(other) {
        return HexString.fromHex((0, misc_1.hexXor)(this.toBigEndian(), other.toBigEndian()));
    }
    /**
     * Export as ASCII string
     */
    toAscii() {
        return (0, convert_1.hexstring2str)(this.toBigEndian());
    }
    /**
     * Export as number
     * @param asLittleEndian - whether export as little endian number, default to be false
     */
    toNumber(asLittleEndian = false) {
        return parseInt(asLittleEndian ? this.toLittleEndian() : this.toBigEndian(), 16);
    }
    /**
     * Export to ArrayBuffer in Uint8Array
     * @param asLittleEndian - whether export as little endian array, default to be false
     */
    toArrayBuffer(asLittleEndian = false) {
        return (0, convert_1.hexstring2ab)(asLittleEndian ? this.toLittleEndian() : this.toBigEndian());
    }
    /**
     * Export as a base64-encoded string.
     * @param asLittleEndian - whether to encode as little endian, default to be false
     */
    toBase64(asLittleEndian = false) {
        return (0, basic_1.hex2base64)(asLittleEndian ? this.toLittleEndian() : this.toBigEndian());
    }
    static fromHex(str, littleEndian = false) {
        if (typeof str === "object" && str instanceof HexString) {
            return new HexString(str.toBigEndian());
        }
        return new HexString(str, littleEndian);
    }
    /**
     * Get HexString instance from a ASCII string
     */
    static fromAscii(str) {
        const hex = (0, convert_1.str2hexstring)(str);
        return new HexString(hex);
    }
    /**
     * Get HexString instance from a number
     * @param littleEndian - whether `num` is little endian
     */
    static fromNumber(num) {
        const rawHex = num.toString(16);
        if (rawHex.length % 2 !== 0) {
            return new HexString("0" + rawHex);
        }
        return new HexString(rawHex);
    }
    /**
     * Get HexString instance from array buffer
     * @param littleEndian - whether `arr` is little endian
     */
    static fromArrayBuffer(arr, littleEndian = false) {
        return new HexString((0, convert_1.ab2hexstring)(arr), littleEndian);
    }
    /**
     * Get HexString instance from a Base64-encoded string
     * @param littleEndian - whether the decoded hexstring is little endian
     */
    static fromBase64(encodedString, littleEndian = false) {
        return new HexString((0, basic_1.base642hex)(encodedString), littleEndian);
    }
}
exports.HexString = HexString;
_HexString_value = new WeakMap();
