"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxAttrUsage = void 0;
/**
 * Enum for TransactionAttributeUsage
 */
var TxAttrUsage;
(function (TxAttrUsage) {
    TxAttrUsage[TxAttrUsage["Url"] = 129] = "Url";
})(TxAttrUsage = exports.TxAttrUsage || (exports.TxAttrUsage = {}));
exports.default = TxAttrUsage;
