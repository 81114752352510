import React from "react"

import AccountContext from "../../contexts/AccountContext"
import QRCode from "react-qr-code";


export default function ViewPrivateKey(props) {

    const { onDismiss, isOpen } = props
    const { account, chestKey, setChestKey } = React.useContext(AccountContext)
    const [isCopied, setIsCopied] = React.useState(false)
    const copyToClipboard = () => {
        navigator.clipboard.writeText(account._WIF)
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1000);
    }
    return (

        <div className="fixed bottom-0 left-0 flex flex-col items-center justify-center w-full h-5/6 bg-slate-800 z-50 rounded-t-xl overflow-hidden drop-shadow-[0_-9px_17px_rgba(0,0,0,0.25)]">
            <div className="flex items-center bg-primary w-full px-3">
                <div className="flex-none w-10">

                </div>
                <p className=" text-center  font-bold w-full uppercase py-3">Private Key</p>
                <div className=" h-full flex-none ml-auto flex items-center justify-center">
                    <button onClick={onDismiss} className="h-full">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className=" flex flex-col items-center justify-center w-full h-full p-6 ">
                <div className="p-4 bg-white rounded-lg">
                    <QRCode size={220} value={account._WIF} />
                </div>
                <div className=" flex flex-wrap  mt-6">
                    <p className="text-white font-semibold break-all">{account._WIF}</p>
                </div>
                <button onClick={copyToClipboard} className="w-full gap-2 flex items-center justify-center mt-4 border p-2 border-white text-white font-medium rounded ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                    </svg>
                    {
                        isCopied ?
                            <span>Copied!</span>
                            :
                            <span>Copy to Clipboard</span>
                    }

                </button>

                <a href="https://neo.org/neogas#wallets" target="_blank" className="mt-auto flex items-center justify-center rounded-lg w-full overflow-hidden shadow-xl border border-gray-100 text-white">
                        <div className="w-16 h-16 p-1 flex items-center justify-center">
                            <svg width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 7.2519V32.3053L16.2863 38.1396V12.8679L33.8517 6.4095L17.9087 0.699951L0 7.2519Z" fill="#00E599" />
                                <path d="M17.5967 13.8038V27.3133L33.8517 33.1164V7.78223L17.5967 13.8038Z" fill="#00AF92" />
                            </svg>

                        </div>
                        <div className="pl-3">
                            <p className="font-bold leading-none">Neo.org</p>
                            <p>Download wallet at Neo.org</p>
                        </div>
                        <div className="ml-auto pr-3">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11.88 9.54L6.92 14.5L5.51 13.09L10.47 8.13L8.34 6L13.99 6.01L14 11.66L11.88 9.54Z" fill="#00E599" />
                            </svg>

                        </div>
                    </a>

            </div>

        </div>

    )

}