import React from "react"
import AccountContext from "../../contexts/AccountContext";
import BlacksmithPuppet from "./../../assets/puppet_blacksmith.png"
import BlacksmithChest from "./../../assets/blacksmith_chest.png"
import Header from "../Components/Header";

export default function ConnectWalletConnect(props) {
    const { onApprove, onCancel, chestMetadata } = props
    const { account, chestKey, puppetTokenId, goldPuppetTokenId } = React.useContext(AccountContext)
    
    const chestConfigurations = {
        "green": {
            chestColor:"text-primary",
            chestKeyColor:"text-primary",
        },
        "blue": {
            chestColor:"text-blue-600",
            chestKeyColor:"text-blue-600",
        },
        "gold": {
            chestColor:"text-yellow-400",
            chestKeyColor:"text-yellow-400",
        },
         "blacksmith": {
            chestColor:"text-black",
            chestKeyColor:"text-black",
        }
    }


    const chestColor = () => {
        var config = chestConfigurations[chestMetadata.chestColor]
        if (config) {
            return config.chestColor
        }
        return "text-gray-600"
    }

    const chestKeyColor = () => {
        var config = chestConfigurations[chestMetadata.chestColor]
        if (config) {
            return config.chestKey
        }
        return "text-gray-600"
    }

    return (

        <div className="p-6 text-secondary absolute inset-0 flex flex-col items-center w-full h-full bg-white">
            <div className="mt-6">
                <Header></Header>
            </div>
            <div className="py-6 flex items-center justify-center">
                {
                    chestKey === "blacksmith" ? 
                    <img src={BlacksmithPuppet} className="w-20 h-20 object-cover"/>
                    :
                    <div className="bg-slate-600 rounded-xl shadow-xl w-20 h-20 flex items-center justify-center">
                    <svg className={` h-12 w-12 stroke-current ${chestKeyColor()}`} width="80" height="90" viewBox="0 0 80 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.27991 11.8113L23.267 28.7983C23.9702 29.5016 24.9241 29.8967 25.9186 29.8967H55.1826C56.1771 29.8967 57.131 29.5016 57.8342 28.7983L74.8213 11.8113" strokeWidth="3.88" strokeLinecap="square" />
                        <path d="M48.9326 38.3384V52.5116C48.9326 54.0847 49.4272 55.618 50.3465 56.8945L68.5587 82.1844" strokeWidth="3.88" strokeLinecap="square" />
                        <path d="M32.2247 38.3384V52.5116C32.2247 54.0847 31.73 55.618 30.8108 56.8945L12.5986 82.1844" strokeWidth="3.88" strokeLinecap="square" />
                        <path d="M10.7818 29.8966H2.39459" strokeWidth="3.88" strokeLinecap="square" />
                        <path d="M78 29.8966H69.6128" strokeWidth="3.88" strokeLinecap="square" />
                        <path d="M33.6942 72.6426V87.6108" strokeWidth="3.88" strokeLinecap="square" />
                        <path d="M46.4112 72.6426V87.6108" strokeWidth="3.88" strokeLinecap="square" />
                        <circle cx="40.4512" cy="11.1507" r="8.96025" strokeWidth="3.88" strokeLinecap="square" />
                    </svg>
                </div>
                }
                
                <div className="text-3xl text-gray-400 flex items-center justify-center">
                    <svg width="75" height="17" viewBox="0 0 75 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="13" cy="9" r="2" fill="#D0E8EB" fillOpacity="0.6" />
                        <circle cx="2" cy="9" r="2" fill="#D0E8EB" fillOpacity="0.6" />
                        <path d="M45.5 2.98C48.5451 2.98 51.02 5.45493 51.02 8.5C51.02 11.5451 48.5451 14.02 45.5 14.02H38.95V16.75H45.5C50.0539 16.75 53.75 13.0539 53.75 8.5C53.75 3.94607 50.0539 0.25 45.5 0.25H38.95V2.98H45.5ZM30.45 7.05V9.95H43.55V7.05H30.45ZM28.5 16.75H35.05V14.02H28.5C25.4549 14.02 22.98 11.5451 22.98 8.5C22.98 5.45493 25.4549 2.98 28.5 2.98H35.05V0.25H28.5C23.9461 0.25 20.25 3.94607 20.25 8.5C20.25 13.0539 23.9461 16.75 28.5 16.75Z" fill="#D0E8EB" fillOpacity="0.8" stroke="white" strokeWidth="0.5" />
                        <circle cx="73" cy="9" r="2" fill="#D0E8EB" fillOpacity="0.6" />
                        <circle cx="62" cy="9" r="2" fill="#D0E8EB" fillOpacity="0.6" />
                    </svg>

                </div>

                <div className="bg-white rounded-xl shadow-xl w-20 h-20 flex items-center justify-center">
                    {
                        chestKey === "blacksmith" ? 
                        <img src={BlacksmithChest} className="w-16 h-16 object-cover"/>
                        :
                        <svg className={`fill-current ${chestColor()}`} width="55" height="72" viewBox="0 0 55 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.1738 70.1985L1.23407 58.9529L18.408 3.61713L27.1738 1.4751L34.1507 3.61713L53.1135 58.9529L27.1738 70.1985Z" />
                        <path d="M27.1738 70.1985L1.23407 58.9529L18.408 3.61713M27.1738 70.1985L53.1135 58.9529L34.1507 3.61713M27.1738 70.1985V6.11616M34.1507 3.61713L27.1738 6.11616M34.1507 3.61713L27.1738 1.4751L18.408 3.61713M27.1738 6.11616L18.408 3.61713" stroke="white" strokeWidth="2" />
                    </svg>
                    }
                    
                </div>
            </div>
            <div className="text-center">

                <div>
                    {
                       (chestMetadata.chestColor !== chestKey && chestMetadata.chestColor !== "gold") ||  (chestMetadata.chestColor === "gold" && goldPuppetTokenId === null) ?
                            <div className="flex flex-col items-center ">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                                <p className="font-bold uppercase mt-3">OOOPS! IT LOOKS LIKE YOU'VE SCANNED THE WRONG COLOR CHEST</p>
                                <p className="mt-1">You have a {chestKey} key, you must scan the {chestKey} chest to continue</p>
                            </div>
                            :
                            <div>
                                <p className="font-semibold text-lg mt-3 uppercase">Connect to {chestMetadata.chestColor} Chest?</p>
                                <p className="px-6 text-center mt-1 font-semibold">By clicking accept, you allow chest to connect to your wallet.</p>
                            </div>
                    }
                </div>
            </div>
            <div className="mt-auto flex flex-col gap-6 items-center justify-between w-full">
                {chestMetadata.chestColor === chestKey || (chestMetadata.chestColor == "gold" && goldPuppetTokenId !== null) ?
                    <div className="flex items-center w-full justify-center gap-12">
                        <div className="text-center">
                            <button onClick={onCancel} className="font-semibold text-red-500 border border-red-500 rounded-full w-20 h-20 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <p className="text-red-500 mt-2 font-semibold">Decline</p>
                        </div>
                        <div className="text-center">
                            <button onClick={onApprove} className="font-semibold text-primary border border-primary rounded-full w-20 h-20 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                </svg>
                            </button>
                            <p className="text-primary mt-2 font-semibold">Accept</p>
                        </div>
                    </div>
                    :
                    <button onClick={onCancel} className="font-semibold text-gray-600 py-2 w-full">Close</button>
                }

            </div>
        </div>
    )
}