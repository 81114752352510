const SocialIcons = {
    "medium": "/assets/socials/medium.png",
    "discord": "/assets/socials/discord.png",
    "youtube": "/assets/socials/youtube.png",
    "github": "/assets/socials/github.png",
    "reddit": "/assets/socials/reddit.png",
    "twitter": "/assets/socials/twitter.png",
    "facebook": "/assets/socials/facebook.png",
    "telegram": "/assets/socials/telegram.png",
    "instagram": "/assets/socials/instagram.png",
}
// "" : {},
const Assets = {
    "0xf0151f528127558851b39c2cd8aa47da7418ab28": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0xf0151f528127558851b39c2cd8aa47da7418ab28.png",
        id: "0xf0151f528127558851b39c2cd8aa47da7418ab28",
        name: "Flamingo Finance",
        symbol: "FLM",
        decimals: 8,
        description: "Flamingo Finance is an interoperable full-stack DeFi protocol with features such as cross-chain asset gateways, perpetual contract trading, token swaps, and more.",
        website_url: "https://flamingo.finance",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/FlamingoFinance",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/r/FlamingoDeFi/",
            },
            {
                id: "youtube",
                url: "https://www.youtube.com/c/FlamingoFinance/featured",
            },
            {
                id: "discord",
                url: "https://discord.gg/xGp9gbM",
            },
            {
                id: "medium",
                url: "https://medium.com/flamingo-finance/",
            },
            {
                id: "github",
                url: "https://github.com/flamingo-finance",
            }

        ]
    },
    "0x9b049f1283515eef1d3f6ac610e1595ed25ca3e9": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0x9b049f1283515eef1d3f6ac610e1595ed25ca3e9.png",
        id: "0x9b049f1283515eef1d3f6ac610e1595ed25ca3e9",
        name: "GhostMarket",
        symbol: "GM",
        decimals: 8,
        description: "GhostMarket is a cross-chain NFT marketplace. You can use it to create, sell, buy, and discover Neo N3, Polygon, Avalanche, Binance Smart Chain, Ethereum, and Phantasma digital collectibles.",
        website_url: "https://ghostmarket.io/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/ghostmarketio",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/r/ghostmarket/",
            },
            {
                id: "discord",
                url: "https://discord.com/invite/THHTS4F",
            },
            {
                id: "telegram",
                url: "https://t.me/GhostMarketAnn",
            },
            {
                id: "medium",
                url: "https://medium.com/ghostmarket",
            },
            {
                id: "github",
                url: "https://github.com/onblockio",
            },
            {
                id: "instgram",
                url: "https://instagram.com/ghostmarket.io",
            }
        ]
    },
    "0x322b5a366ca724801a1aa01e669b5f3d7f8c7f6f": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0x322b5a366ca724801a1aa01e669b5f3d7f8c7f6f.png",
        id: "0x322b5a366ca724801a1aa01e669b5f3d7f8c7f6f",
        name: "DogeRift",
        symbol: "DOGER",
        decimals: 8,
        description: `DogeRift is a play-to-earn 2D and 3D Metaverse game worlds using NFTs. Users will first need to purchase a Doge NFT from the DogeRift marketplace before they can start playing. 

        Playing the game yields DOGEF tokens which can be used on various in-game mechanics such as strengthening Doges, creating new Doges, crafting items, and customizing your land. DOGEF can also be sold to other players. 
        
        DogeRift also has a governance token, DOGER, which can be gained by participating in voting, staking, and in-game events.`,
        website_url: "https://dogerift.com",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/DogeRift",
            },
            {
                id: "youtube",
                url: "https://www.youtube.com/channel/UCy3Ig6JQcrLqNF19oU-ic6A",
            },
            {
                id: "discord",
                url: "https://discord.gg/SwztdKh5nC",
            },
            {
                id: "instagram",
                url: "https://www.instagram.com/dogerift/",
            }
        ]
    },
    "0x8c07b4c9f5bc170a3922eac4f5bb7ef17b0acc8b": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0x8c07b4c9f5bc170a3922eac4f5bb7ef17b0acc8b.png",
        id: "0x8c07b4c9f5bc170a3922eac4f5bb7ef17b0acc8b",
        name: "Lyrebird",
        symbol: "LRB",
        description: "Lyrebird is a fractional-algorithmic stablecoin protocol built on Neo N3. Lyrebird relies on an efficient arbitrage mechanism during economic expansion and falls back to its fractional reserves during economic contraction to support its currency pegs.",
        website_url: "https://lyrebird.finance",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/LyrebirdFinance",
            },
            {
                id: "discord",
                url: "https://discord.gg/sSXtfm92vc",
            },
            {
                id: "telegram",
                url: "https://t.me/GhostMarketAnn",
            },
            {
                id: "medium",
                url: "https://lyrebird-finance.medium.com/",
            },
            {
                id: "github",
                url: "https://github.com/lyrebird-finance",
            },
            {
                id: "instgram",
                url: "https://instagram.com/ghostmarket.io",
            }
        ]
    },
    "0xaab90afe15c791872fcd2f490346682294b436ed": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0xaab90afe15c791872fcd2f490346682294b436ed.png",
        id: "0xaab90afe15c791872fcd2f490346682294b436ed",
        name: "Supernova",
        symbol: "LFC",
        decimals: 9,
        description: `Supernova is an upcoming sci-fi sandbox survival MMORPG with a multi-blockchain architecture and play2earn economic model with total freedom of action in the open world. Want to see distant planets? Become an explorer! Want to get the resources without working for them? Become a space pirate! Don't like pirates? Become a military commander! Every path you can think of is open in our game. Literally. 

        Every item in our game was invented by someone, crafted, stolen, broken, and repaired - Supernova has this real-life-like economy that allows every player to make some money. `,
        website_url: "https://sunday.games/supernova/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/SupernovaVerse",
            },
            {
                id: "facebook",
                url: "https://www.facebook.com/SupernovaVerse",
            },
            {
                id: "youtube",
                url: "https://www.youtube.com/playlist?list=PLjE7WiirRW9ow4kBzhDbL7dbcdpJLA0C5",
            },
            {
                id: "telegram",
                url: "https://t.me/+jNNGMc-lkzJlMjZi",
            },
            {
                id: "github",
                url: "https://github.com/lyrebird-finance",
            },
            {
                id: "instgram",
                url: "https://instagram.com/ghostmarket.io",
            }
        ]
    },
    "0x76a8f8a7a901b29a33013b469949f4b08db15756": {
        protocol: "nep11",
        icon_url: "/assets/tokens/0x76a8f8a7a901b29a33013b469949f4b08db15756.png",
        id: "0x76a8f8a7a901b29a33013b469949f4b08db15756",
        name: "PROPS",
        symbol: "PROPS",
        description: `PROPS is aiming to significantly improve the ease of use and scalability of both smart contracts and off-chain integrations within the ecosystem to enhance the developer experience of the N3 platform. 

        It currently features four smart contracts: Puppets (general-purpose utility NFTs), Collection (stores arrays of immutable data and metadata), Dice (wraps the RNG features of N3), and Generator (recursively generates procedural objects and triggers probabilistic events with a high degree of tunability).`,
        website_url: "https://props.coz.io/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/coz_props",
            },
            {
                id: "discord",
                url: "https://discord.com/invite/Gbf9QzhS",
            },
            {
                id: "medium",
                url: "https://medium.com/proof-of-working/introducing-props-47e9d026724",
            },
            {
                id: "github",
                url: "https://github.com/CityOfZion/props",
            }
        ]
    },
    "0xcd10d9f697230b04d9ebb8594a1ffe18fa95d9ad": {
        protocol: "nep11",
        icon_url: "/assets/tokens/0xcd10d9f697230b04d9ebb8594a1ffe18fa95d9ad.png",
        id: "0xcd10d9f697230b04d9ebb8594a1ffe18fa95d9ad",
        name: "NeoVerse",
        symbol: "N3",
        description: "To celebrate the launch of the N3 MainNet, NGD launched Neoverse - a NFT mint and collection event together with all developer communities. The Neoverse N3 Collector's Edition NFTs are not only invaluable collectibles, but also carry various “VIP” privileges within the Neo ecosystem.",
        website_url: "https://neoverse.neo.org/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/Neo_Blockchain",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/r/NEO/",
            },
            {
                id: "facebook",
                url: "https://www.facebook.com/NeoBlockchainOfficial",
            },
            {
                id: "discord",
                url: "https://discord.com/invite/rvZFQ5382k",
            },
            {
                id: "telegram",
                url: "https://t.me/NEO_EN",
            },
            {
                id: "medium",
                url: "https://medium.com/neo-smart-economy",
            }
        ]
    },
    "0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5.png",
        id: "0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5",
        name: "NEO Token",
        symbol: "NEO",
        decimals: 0,
        description: `NEO is the governance token of the Neo blockchain. It provides its holder the right to elect Neo Council members as well as claim GAS needed to perform transactions on the Neo network. 

        NEO has a total supply of 100,000,000 and is indivisible.`,
        website_url: "https://neo.org/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/Neo_Blockchain",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/r/NEO/",
            },
            {
                id: "facebook",
                url: "https://www.facebook.com/NeoBlockchainOfficial",
            },
            {
                id: "discord",
                url: "https://discord.com/invite/rvZFQ5382k",
            },
            {
                id: "telegram",
                url: "https://t.me/NEO_EN",
            },
            {
                id: "medium",
                url: "https://medium.com/neo-smart-economy",
            }
        ]
    },
    "0xd2a4cff31913016155e38e474a2c06d08be276cf": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0xd2a4cff31913016155e38e474a2c06d08be276cf.png",
        id: "0xd2a4cff31913016155e38e474a2c06d08be276cf",
        name: "Gas Token",
        symbol: "GAS",
        decimals: 8,
        description: `GAS is the utility token of the Neo blockchain. It is used to pay for network and system fees and can be earned by holding NEO tokens.`,
        website_url: "https://neo.org/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/Neo_Blockchain",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/r/NEO/",
            },
            {
                id: "facebook",
                url: "https://www.facebook.com/NeoBlockchainOfficial",
            },
            {
                id: "discord",
                url: "https://discord.com/invite/rvZFQ5382k",
            },
            {
                id: "telegram",
                url: "https://t.me/NEO_EN",
            },
            {
                id: "medium",
                url: "https://medium.com/neo-smart-economy",
            }
        ]
    },
    "TODO-Humswap": {//TODO update smartcontract hash
        protocol: "nep11",
        icon_url: "/assets/tokens/___.png",
        id: "___",
        name: "Humswap",
        symbol: "___",
        description: "An NFT and DeFi platform that will offer liquidity pools for various tokens, a marketplace for NFTs, and an opportunity for users to play games and win prizes.",
        website_url: "https://www.humswap.org",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/HumswapNeo",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/user/Humswap",
            },
            {
                id: "discord",
                url: "https://discord.com/invite/3ZQ2nmgUy3",
            },
            {
                id: "medium",
                url: "https://medium.com/@humswap",
            },
            {
                id: "github",
                url: "https://github.com/Humswap",
            }
        ]
    },
    "0xf456649d0b8f331596035a07f977cb8d8dbf0122": {
        protocol: "nep11",
        icon_url: "/assets/tokens/0xf456649d0b8f331596035a07f977cb8d8dbf0122.png",
        id: "0xf456649d0b8f331596035a07f977cb8d8dbf0122",
        name: "Blockchain Cuties",
        symbol: "CUTIE",
        description: `Blockchain Cuties is a collectible crypto game with adventures. Each cutie is an unique NFT. Users can to collect, breed, test their skills in battles, arm, and level up characters.`,
        website_url: "https://blockchaincuties.com/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/BlockchainCutie",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/r/blockchaincuties/",
            },
            {
                id: "facebook",
                url: "https://www.facebook.com/blockchaincuties/",
            },
            {
                id: "discord",
                url: "https://discord.gg/R3bhN5h",
            },
            {
                id: "telegram",
                url: "https://t.me/blockchaincuties_en",
            },
            {
                id: "medium",
                url: "https://medium.com/blockchain-cuties",
            },
            {
                id: "instagram",
                url: "https://www.instagram.com/blockchain_cuties/",
            }
        ]
    },
    "0x1f368c615ac4a85e8d87e80448f366778597f1bf": {
        protocol: "nep11",
        icon_url: "/assets/tokens/0x1f368c615ac4a85e8d87e80448f366778597f1bf.png",
        id: "0x1f368c615ac4a85e8d87e80448f366778597f1bf",
        name: "IlexNFT",
        symbol: "ILEX22",
        description: `Ilex is an emerging web3 brand. Its iconic symbol is a [pole] or [-x➖✖️] in respect to our vision “all web3 motions are polar”.

        Its aims to create a cultural icon that will outlast any web3 element. The ilex avatar “POLEMAN” has a total supply of 9,999. POLEMAN will reside on multiple chains and be able to move across chains. Each POLEMAN is hand-crafted, creating unique characters with POLE for everyone. POLEMANs are building the largest multi-chain web3 fashion ecosystem.
        
        A lot of us here are POLEMAN. We just need to stand up.`,
        website_url: "https://www.ilexnft.com",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/ilexnft",
            },
            {
                id: "instagram",
                url: "https://www.instagram.com/ilexnft/",
            }
        ]
    },
    "0xaa4fb927b3fe004e689a278d188689c9f050a8b2": {
        protocol: "nep11",
        icon_url: "/assets/tokens/0xaa4fb927b3fe004e689a278d188689c9f050a8b2.png",
        id: "0xaa4fb927b3fe004e689a278d188689c9f050a8b2",
        name: "TOTHEMOON",
        symbol: "TTM",
        description: `TOTHEMOON is a strategic community-building NFT game built on Neo N3 where collectors engage with the moon-themed metaverse. 

        The TOTHEMOON team aims to establish this metaverse by layering and integrating NFTs in a way that establishes a thriving supply and demand economy. `,
        website_url: "https://tothemoon.net",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/tothemoon_net",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/r/tothemoonuniverse/",
            },
            {
                id: "discord",
                url: "https://discord.gg/BKtXzfbwpt",
            },
            {
                id: "telegram",
                url: "https://t.me/tothemoonuniverse",
            },
            {
                id: "medium",
                url: "https://tothemoonuniverse.medium.com/",
            },
        ]
    },
    "0x78e1330db47634afdb5ea455302ba2d12b8d549f": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0x78e1330db47634afdb5ea455302ba2d12b8d549f.png",
        id: "0x78e1330db47634afdb5ea455302ba2d12b8d549f",
        name: "Switcheo",
        symbol: "SWTH",
        decimals: 8,
        description: `Switcheo is a non-custodial cross-chain exchange that integrates multiple third-party wallets.`,
        website_url: "https://www.switcheo.com/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/switcheonetwork",
            },
            {
                id: "reddit",
                url: "https://www.reddit.com/r/Switcheo/",
            },
            {
                id: "facebook",
                url: "https://www.facebook.com/SwitcheoExchange/",
            },
            {
                id: "youtube",
                url: "https://www.youtube.com/channel/UCqZ9sxvw-0UoHzno4-d97oQ/videos",
            },
            {
                id: "telegram",
                url: "https://t.me/switcheo",
            },
            {
                id: "medium",
                url: "https://medium.com/switcheo",
            },
            {
                id: "github",
                url: "https://github.com/Switcheo",
            },
        ]
    },
    "0x19ed09dadac28e6b6a2f76588516ef681aff29b1": {
        protocol: "nep17",
        icon_url: "/assets/tokens/0x19ed09dadac28e6b6a2f76588516ef681aff29b1.png",
        id: "0x19ed09dadac28e6b6a2f76588516ef681aff29b1",
        name: "Mega Oasis",
        symbol: "METAPANACEA",
        description: `Mega Oasis is a boutique NFT marketplace offering access to exclusive NFT collections created by world renowned artists. It is targeted at both existing NFT collectors as well as traditional art lovers.

        Mega Oasis launched with the Meta Panacea collection by Zhenchen Liu, whose pieces can be found in various museums throughout the world, including Grand Palais, Petit Palais, and Centre Pompidou in Paris, Reina Sofía National Art Museum in Madrid, and the Museum of Natural History in New York. Meta Panacea is based on an existing collection of physical art pieces, Panacea, created by Liu.`,
        website_url: "https://megaoasis.io/",
        socials: [
            {
                id: "twitter",
                url: "https://twitter.com/MegaOasis_nft",
            },
            {
                id: "discord",
                url: "https://discord.gg/FxfSgTCUuN",
            },
        ]
    },
}


const Events = {
    "consensus": {
        "id": "consensus",
        "name": "CONSENSUS 2022",
        "description":"This wallet contains GAS and a Puppet NFT. Scan the QR code on a chest and use your Puppet to unlock it.",
        "prize_description":"Every participant has a chance to win over $1,000 in token prizes.",
        "ends_at": new Date("Sun Jun 12 2022 18:00:00 CDT")
    },
    "token2049": {
        "id": "token2049",
        "name": "TOKEN2049",
        "ends_at": new Date("Thu Sep 29 2022 19:00:00 GMT+8"),
        "description":"This wallet contains GAS and a Puppet NFT. Scan the QR code on a chest and use your Puppet to unlock it.",
        "prize_description":"Every participant has a chance to win over $1,000 in token prizes.",
    }
}

const Global = {
    Assets,
    SocialIcons,
    Events
}
export default Global;