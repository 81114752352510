import React from "react"
import { useNavigate, createSearchParams } from 'react-router-dom';
import QRCodeScanner from "../Components/QRCodeScanner";
import ConnectWalletConnect from "../Components/ConnectWalletConnect";
import AccountContext from "../../contexts/AccountContext";
// import WalletConnectContext from "../../contexts/WalletConnectContext";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import {useWalletConnect} from "../../contexts/WalletConnectContext";
import {DEFAULT_NAMESPACES} from "../../constants";
import BlacksmithPuppet from "./../../assets/puppet_blacksmith.png"
export default function AssetInfo(props) {

  const { account, chestKey, puppetTokenId } = React.useContext(AccountContext)
  // const { wcClient, isConnected, chestMetadata, session, proposal,setIsConnected, setSession, setChestMetadata } = React.useContext(WalletConnectContext)

  const navigate = useNavigate();

  const [requestConnectPresented, setRequestConnectPresented] = React.useState(false)
  const [scannerIsPresented, setScannerIsPresented] = React.useState(false)

  const [connecting, setConnecting] = React.useState(false)
  const [loadingKey, setLoadingKey] = React.useState(true)
  const walletConnectCtx = useWalletConnect()

  const isConnected = walletConnectCtx.sessions.length > 0
  

  const chestMetadata = () => {
   
    if (walletConnectCtx.sessionProposals === undefined || walletConnectCtx.sessionProposals.length === 0) {
      return null
    }
    return walletConnectCtx.sessionProposals[0].params.proposer.metadata
  }
  const keyConfigurations = {
    "green":{
      color:"text-primary"
    },
    "blue":{
      color:"text-blue-600"
    },
    "blacksmith":{
      color:"text-black"
    },
  }
  
  const onScanned = async (scannedString) => {
    setScannerIsPresented(false)
    let uri = scannedString
    setConnecting(true)
    try {
      await walletConnectCtx.onURI(uri)
    } catch (e) {
      setConnecting(false)
      alert(e)
    }
  }

  const onApproveWalletConnect = () => {
    setRequestConnectPresented(false)
    let approved = true;
    const firstProposal = walletConnectCtx.sessionProposals[0]
    handleSessionUserApproval(approved, firstProposal);
  }

  const onCancelWalletConnect = () => {
    setRequestConnectPresented(false)
    let approved = false;
    const firstProposal = walletConnectCtx.sessionProposals[0]
    handleSessionUserApproval(approved, firstProposal); 
  }

  const handleSessionUserApproval = async (approved, proposal) => {
    setConnecting(true)
    if (approved) {
      await walletConnectCtx.approveSession(proposal, [{
        address: account.address ?? '',
        chain: "neo3:mainnet"
      }], DEFAULT_NAMESPACES)  
    } else {
      walletConnectCtx.rejectSession(proposal)
    }
  
  }

  //when getting proposal and is not connected yet
  React.useEffect(() => {
    if (walletConnectCtx.sessionProposals.length === 0) return;

    if (walletConnectCtx.sessions.length > 0) return;

    setRequestConnectPresented(true)
    setConnecting(false)
  }, [walletConnectCtx.sessionProposals, walletConnectCtx.sessions])

  //when a user approved a connection
  React.useEffect(() => {

    if (walletConnectCtx.sessions.length > 0) {
      console.log("when a user approved a connection")
      navigate("/connected", { replace: true })
    };
  }, [walletConnectCtx.sessions])

  React.useEffect(()=>{
    if (puppetTokenId !== null && chestKey !== null) {
      setLoadingKey(false)
    } else {
      setLoadingKey(true)
    }
  },[puppetTokenId, chestKey]) 

  return (
    <div className=" absolute inset-0 text-secondary">
      {
        connecting === true?
          <Loading></Loading>
          :
          null
      }
      <div className="p-3 container mx-auto relative flex flex-col h-full ">
        {
          isConnected === true ?
            <div className={`flex items-center ${keyConfigurations[chestKey] ? keyConfigurations[chestKey].color : "text-gray-100"}`}>
              <svg className="fill-current mr-1" width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.72 6C21.72 3.948 20.052 2.28 18 2.28H13.2V0H18C21.312 0 24 2.688 24 6C24 9.312 21.312 12 18 12H13.2V9.72H18C20.052 9.72 21.72 8.052 21.72 6ZM16.8 7.2H7.2V4.8H16.8V7.2ZM6 0H10.8V2.28H6C3.948 2.28 2.28 3.948 2.28 6C2.28 8.052 3.948 9.72 6 9.72H10.8V12H6C2.688 12 0 9.312 0 6C0 2.688 2.688 0 6 0Z" />
              </svg>
              <span className="font-semibold text-sm uppercase">CONNECTED</span>
            </div>
            : null
        }
        <div className="mt-6">
          <Header></Header>
        </div>
        <div className="h-full flex flex-col items-center justify-center">
          {
            account !== null ?
              <div className="flex flex-col items-center justify-center p-6">
                
                  {
                     chestKey === "blacksmith" ?
                    <img className="h-40 w-40 " src={BlacksmithPuppet}/>
                    :
                    <div className="bg-slate-600 p-6 rounded-xl shadow-xl mb-6 w-40 h-40">
                    <svg className={` h-24 w-24 stroke-current ${keyConfigurations[chestKey] ? keyConfigurations[chestKey].color : "text-gray-100"}`} width="80" height="90" viewBox="0 0 80 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.27991 11.8113L23.267 28.7983C23.9702 29.5016 24.9241 29.8967 25.9186 29.8967H55.1826C56.1771 29.8967 57.131 29.5016 57.8342 28.7983L74.8213 11.8113" strokeWidth="3.88" strokeLinecap="square" />
                    <path d="M48.9326 38.3384V52.5116C48.9326 54.0847 49.4272 55.618 50.3465 56.8945L68.5587 82.1844" strokeWidth="3.88" strokeLinecap="square" />
                    <path d="M32.2247 38.3384V52.5116C32.2247 54.0847 31.73 55.618 30.8108 56.8945L12.5986 82.1844" strokeWidth="3.88" strokeLinecap="square" />
                    <path d="M10.7818 29.8966H2.39459" strokeWidth="3.88" strokeLinecap="square" />
                    <path d="M78 29.8966H69.6128" strokeWidth="3.88" strokeLinecap="square" />
                    <path d="M33.6942 72.6426V87.6108" strokeWidth="3.88" strokeLinecap="square" />
                    <path d="M46.4112 72.6426V87.6108" strokeWidth="3.88" strokeLinecap="square" />
                    <circle cx="40.4512" cy="11.1507" r="8.96025" strokeWidth="3.88" strokeLinecap="square" />
                  </svg>
                  </div>
                  }
                  
                
                <p className="font-semibold uppercase">{chestKey} PUPPET KEY #{puppetTokenId}</p>
                <p className="text-center">You have in your possession a <span>{chestKey}</span> Puppet Key. Please go to the <span>{chestKey}</span> chest and scan its QR code to continue.</p>
              </div>
              :
              null
          }

        </div>


        <div className="mt-auto p-3 w-full">

          {
            account !== null && isConnected == false && walletConnectCtx.initialized ?
              <button onClick={(e) => { setScannerIsPresented(!scannerIsPresented); }} className="bg-primary w-full py-2 font-semibold text-white uppercase">Scan QR Code</button>
              : null
          }
        </div>

      </div>
      
      {
        requestConnectPresented === true ?
          <ConnectWalletConnect chestMetadata={chestMetadata()} onApprove={onApproveWalletConnect} onCancel={onCancelWalletConnect}></ConnectWalletConnect>
          : null
      }
      {
        scannerIsPresented === true ?
          <QRCodeScanner account={account} onScanned={onScanned} onClose={(e) => setScannerIsPresented(false)} />
          : null
      }


    </div>
  )
}   