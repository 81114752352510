import React from "react"

import AccountContext from "../../contexts/AccountContext"
import Neon, { rpc, api, sc } from "@cityofzion/neon-js";
import * as ChestProps from '@cityofzion/props'
import Global from "../../Global";
export default function WithdrawAssets(props) {

    const { onDismiss } = props
    const { account, chestKey, setChestKey } = React.useContext(AccountContext)
    const [toAddress, setToAddress] = React.useState("")
    const [isN3Address, setIsN3Address] = React.useState(false)

    const [processing, setProcessing] = React.useState(false)
    const [txSubmitted, setTxSubmitted] = React.useState(false)

    const Assets = Global.Assets;
    const onChangeAddres = (e) => {
        const value = e.target.value;
        //Check if it's N3 address by simply checking the prefix
        if (value.startsWith("N")=== false) {
            setIsN3Address(false)
            return
        }
        if (Neon.is.address(value)) {
            setToAddress(e.target.value)
            setIsN3Address(true)
        } else {
            setIsN3Address(false)
        }
    }

    const tokensOf = async (
        node,
        networkMagic,
        contractHash,
        address,
        signer
    ) => {
        const method = "tokensOf";

        const params = [sc.ContractParam.hash160(address)];
        const res = await ChestProps.helpers.variableInvoke(node, networkMagic, contractHash, method, params, signer)
        if (signer) {
            return res
        }
        const iterator = res[0]
        if (iterator.iterator && iterator.iterator.length >= 0) {
            return iterator.iterator.map((tokenId) => {
                console.log("tokenId tokenOf",tokenId);
                return ChestProps.helpers.formatter(tokenId)
            })
        }
    }


    const withdraw = async () => {
        const fromAccount = Neon.create.account(account._WIF)

        const node = Neon.create.rpcClient('https://mainnet1.neo.coz.io:443')


        const NEP17Balances = await node.getNep17Balances(fromAccount.address)
        const NEP111Balances = await node.getNep11Balances(fromAccount.address)
       
        for (let balance of NEP111Balances.balance) {

            const hash = balance.assethash;
            //puppet
            if (hash === "0x76a8f8a7a901b29a33013b469949f4b08db15756") {
                console.log("balance", balance)
                console.log("hash", hash)
                const tokenIds = await tokensOf(node.url, Neon.CONST.MAGIC_NUMBER.MainNet, hash, fromAccount.address)
                console.log(tokenIds);
    
                for (let i = 0; i < tokenIds.length; i++) {
                    console.log(tokenIds[i]);
                    const tokenId = atob(tokenIds[i][1].value)
                    console.log("tokenId", tokenId)
                    try {
                        let txid = await ChestProps.api.PuppetAPI.transfer(
                            node.url,
                            Neon.CONST.MAGIC_NUMBER.MainNet,
                            balance.assethash,
                            toAddress,
                            tokenId,
                            fromAccount
                        )
                        console.log(txid);
                    } catch (error) {
                        console.log(error)
                    }  
                }
            } else {
                for (let token of balance.tokens) {
                    const tokenId = token.tokenid.toString()
                    console.log("tokenid", tokenId, "typeof", typeof(tokenId))
                    try {
                        const res = await ChestProps.api.PuppetAPI.transfer(
                            node.url,
                            Neon.CONST.MAGIC_NUMBER.MainNet,
                            balance.assethash,
                            toAddress,
                            tokenId,
                            Neon.create.account(account._WIF)
                        )
    
                        console.log(res) //Your transaction ID
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        }

        let GAS = NEP17Balances.balance.filter((t)=>{return t. assethash === "0xd2a4cff31913016155e38e474a2c06d08be276cf"})
        let nonGAS = NEP17Balances.balance.filter((t)=>{return t. assethash !== "0xd2a4cff31913016155e38e474a2c06d08be276cf"})

        console.log(GAS);
        console.log(nonGAS);

        
        for (let token of nonGAS) {
            let asset = Assets[token.assethash]
            if (asset) {
                console.log("withdrawing...",token)
                const amount = parseInt(token.amount)

                const params = [
                    sc.ContractParam.hash160(fromAccount.address),
                    sc.ContractParam.hash160(toAddress),
                    sc.ContractParam.integer(amount),
                    sc.ContractParam.any('')
                ]
                try {
                    const res = await ChestProps.api.NeoInterface.publishInvoke(
                        node.url,
                        Neon.CONST.MAGIC_NUMBER.MainNet,
                        token.assethash,
                        'transfer',
                        params,
                        fromAccount
                    );
                    console.log(res) //Your transaction ID
                } catch(e) {
                    console.log(e)
                }
            }
        }

        for (let token of GAS) {
            let asset = Assets[token.assethash]
            if (asset) {
                console.log("withdrawing...",token)
                const amount = parseInt(token.amount) - (0.02 * Math.pow(10, asset.decimals))
                console.log(amount)
                const params = [
                    sc.ContractParam.hash160(fromAccount.address),
                    sc.ContractParam.hash160(toAddress),
                    sc.ContractParam.integer(amount),
                    sc.ContractParam.any('')
                ]
                try {
                    const res = await ChestProps.api.NeoInterface.publishInvoke(
                        node.url,
                        Neon.CONST.MAGIC_NUMBER.MainNet,
                        token.assethash,
                        'transfer',
                        params,
                        fromAccount
                    );
                    console.log(res) //Your transaction ID
                } catch(e) {
                    console.log(e)
                }
            }

        }

        setTxSubmitted(true)
        setProcessing(false)

    }

    const onWithdraw = () => {
        setProcessing(true)
        withdraw()
    }



    return (
        <div className="fixed bottom-0 left-0 flex flex-col items-center justify-center w-full h-5/6 bg-slate-800 z-50 rounded-t-xl overflow-hidden drop-shadow-[0_-9px_17px_rgba(0,0,0,0.25)]">
            <div className="flex items-center bg-primary w-full px-3">
                <div className="flex-none w-10">

                </div>
                <p className=" text-center  font-bold w-full uppercase py-3">Withdraw all assets</p>
                <div className=" h-full flex-none ml-auto flex items-center justify-center">
                    <button onClick={onDismiss} className="h-full">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className=" flex flex-col items-center justify-center w-full h-full p-6">
                {
                    processing == false && txSubmitted == true ?
                        <div className="text-center flex flex-col items-center justify-center h-full">
                            <div className="rounded-full border border-primary w-24 h-24 mb-6 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-primary h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p className="text-xl font-bold text-primary">Assets successfully sent</p>
                            <p className="text-white font-semibold">You assets have been successfully sent to your secure wallet.</p>

                            <p className="mt-6 rounded-full text-primary font-bold bg-slate-100 bg-opacity-25 px-4 py-1">
                                {account.address}
                            </p>

                            <svg className="mt-6" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 12L21.885 9.885L13.5 18.255L13.5 -4.5897e-07L10.5 -5.90104e-07L10.5 18.255L2.115 9.885L1.38281e-06 12L12 24L24 12Z" fill="#00E599" />
                            </svg>
                            <p className="mt-6 rounded-full text-primary font-bold bg-slate-100 bg-opacity-25 px-4 py-1">
                                {toAddress}
                            </p>

                            <div className="w-full mt-auto">
                                <a href={`https://dora.coz.io/address/neo3/mainnet/${toAddress}/assets`} target="_blank" >
                                    <button className="disabled:opacity-50 bg-primary py-2 font-bold uppercase text-white w-full">
                                        VIEW DETAILS
                                    </button>
                                </a>
                            </div>
                        </div>
                        : null
                }


                {
                    processing == true && txSubmitted == false ?
                        <div className="text-center flex flex-col items-center justify-center">
                            <div className="rounded-full border border-primary w-24 h-24 mb-6 flex items-center justify-center">
                                <svg width="28" height="46" viewBox="0 0 28 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1H27V14.2L18.3333 23L27 31.8V45H1V31.8L9.66667 23L1 14.2V1ZM22.6667 32.9L14 24.1L5.33333 32.9V40.6H22.6667V32.9ZM14 21.9L22.6667 13.1V5.4H5.33333V13.1L14 21.9ZM9.66667 9.8H18.3333V11.45L14 15.85L9.66667 11.45V9.8Z" fill="#00E599" stroke="#313B46" />
                                </svg>
                            </div>
                            <p className="text-xl font-bold text-primary">Transaction Pending</p>
                            <p className="text-white font-semibold">When your transaction is successful, this screen will be updated.</p>

                        </div>
                        : null
                }
                {
                    processing == false && txSubmitted == false ?
                        <div className=" flex flex-col items-center justify-center w-full h-full">
                            <p className="text-white text-sm">
                                You are about to withdraw all assets from this temporary wallet. Once withdrawn this wallet will be no longer of use.
                                If you don’t have a wallet don’t worry, a selection of compatible wallets can be found at Neo.org
                            </p>
                            <p className="text-white text-sm mt-6">
                                Please enter the address of your secure wallet you wish to withdraw all assets to.
                            </p>
                            <div className="w-full mt-6">
                                <input type="text" onChange={onChangeAddres} className="w-full rounded bg-gray-700 py-2 px-3 focus:outline-none text-white" placeholder="Tap here to paste address" />
                            </div>
                            <div className="w-full mt-auto">
                                <button onClick={(e) => onWithdraw(e)} disabled={toAddress.length > 0 && isN3Address == true ? false : true} className="disabled:opacity-50 bg-primary py-2 font-bold uppercase text-white w-full">WITHDRAW</button>
                            </div>
                        </div>
                        : null
                }

            </div>
        </div>
    )
}