"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractHash = exports.deployContract = exports.addFees = exports.setBlockExpiry = exports.getSystemFee = exports.calculateNetworkFee = void 0;
const neon_core_1 = require("@cityofzion/neon-core");
const nep17_1 = require("./nep17");
const neon_api_1 = require("@cityofzion/neon-api");
/**
 * Calculate the GAS costs for validation and inclusion of the transaction in a block
 * @param transaction - the transaction to calculate the network fee for
 * @param account -
 * @param config -
 *
 * @deprecated use the smartCalculateNetworkFee helper instead.
 */
async function calculateNetworkFee(transaction, account, config) {
    if (transaction.signers.length < 1) {
        throw new Error("Cannot calculate the network fee without a sender in the transaction.");
    }
    const hashes = transaction.getScriptHashesForVerifying();
    let networkFeeSize = transaction.headerSize +
        neon_core_1.u.getSerializedSize(transaction.signers) +
        neon_core_1.u.getSerializedSize(transaction.attributes) +
        neon_core_1.u.getSerializedSize(transaction.script) +
        neon_core_1.u.getSerializedSize(hashes.length);
    const rpcClient = new neon_core_1.rpc.RPCClient(config.rpcAddress);
    let execFeeFactor = 0;
    try {
        const response = await rpcClient.invokeFunction(neon_core_1.CONST.NATIVE_CONTRACT_HASH.PolicyContract, "getExecFeeFactor");
        if (response.state === "FAULT") {
            throw Error;
        }
        execFeeFactor = parseInt(response.stack[0].value);
    }
    catch (e) {
        throw new Error(`Failed to get 'Execution Fee factor' from Policy contract. Error: ${e}`);
    }
    let networkFee = 0;
    hashes.map((hash) => {
        let witnessScript;
        if (hash === account.scriptHash && account.contract.script !== undefined) {
            witnessScript = neon_core_1.u.HexString.fromBase64(account.contract.script);
        }
        if (witnessScript === undefined && transaction.witnesses.length > 0) {
            for (const witness of transaction.witnesses) {
                if (witness.scriptHash === hash) {
                    witnessScript = witness.verificationScript;
                    break;
                }
            }
        }
        if (witnessScript === undefined)
            // should get the contract script via RPC getcontractstate
            // then execute the script with a verification trigger (not yet supported)
            // and collect the gas consumed
            throw new Error("Using a smart contract as a witness is not yet supported in neon-js");
        else if (neon_core_1.sc.isSignatureContract(witnessScript)) {
            networkFeeSize += 67 + neon_core_1.u.getSerializedSize(witnessScript);
            networkFee =
                execFeeFactor *
                    (neon_core_1.sc.OpCodePrices[neon_core_1.sc.OpCode.PUSHDATA1] * 2 +
                        neon_core_1.sc.OpCodePrices[neon_core_1.sc.OpCode.SYSCALL] +
                        neon_core_1.sc.getInteropServicePrice(neon_core_1.sc.InteropServiceCode.SYSTEM_CRYPTO_CHECKSIG));
        }
        else if (neon_core_1.sc.isMultisigContract(witnessScript)) {
            const publicKeyCount = neon_core_1.wallet.getPublicKeysFromVerificationScript(witnessScript.toString()).length;
            const signatureCount = neon_core_1.wallet.getSigningThresholdFromVerificationScript(witnessScript.toString());
            const invocationScriptSize = 66 * signatureCount;
            networkFeeSize +=
                neon_core_1.u.getSerializedSize(invocationScriptSize) +
                    invocationScriptSize +
                    neon_core_1.u.getSerializedSize(witnessScript);
            networkFee +=
                execFeeFactor * neon_core_1.sc.OpCodePrices[neon_core_1.sc.OpCode.PUSHDATA1] * signatureCount;
            const builder = new neon_core_1.sc.ScriptBuilder();
            let pushOpcode = neon_core_1.sc.fromHex(builder.emitPush(signatureCount).build().slice(0, 2));
            // price for pushing the signature count
            networkFee += execFeeFactor * neon_core_1.sc.OpCodePrices[pushOpcode];
            // now do the same for the public keys
            builder.reset();
            pushOpcode = neon_core_1.sc.fromHex(builder.emitPush(publicKeyCount).build().slice(0, 2));
            // price for pushing the public key count
            networkFee += execFeeFactor * neon_core_1.sc.OpCodePrices[pushOpcode];
            networkFee +=
                execFeeFactor *
                    (neon_core_1.sc.getInteropServicePrice(neon_core_1.sc.InteropServiceCode.SYSTEM_CRYPTO_CHECKMULTISIG) *
                        publicKeyCount);
        }
        // else { // future supported contract types}
    });
    try {
        const response = await rpcClient.invokeFunction(neon_core_1.CONST.NATIVE_CONTRACT_HASH.PolicyContract, "getFeePerByte");
        if (response.state === "FAULT") {
            throw Error;
        }
        const nativeContractPolicyFeePerByte = parseInt(response.stack[0].value);
        networkFee += networkFeeSize * nativeContractPolicyFeePerByte;
    }
    catch (e) {
        throw new Error(`Failed to get 'fee per byte' from Policy contract. Error: ${e}`);
    }
    return neon_core_1.u.BigInteger.fromDecimal(networkFee, 0);
}
exports.calculateNetworkFee = calculateNetworkFee;
/**
 * Get the cost of executing the smart contract script
 * @param script - smart contract script
 * @param config -
 * @param signers - signers to set while running the script
 */
async function getSystemFee(script, config, signers) {
    const rpcClient = new neon_core_1.rpc.RPCClient(config.rpcAddress);
    try {
        const response = await rpcClient.invokeScript(script, signers);
        if (response.state === "FAULT") {
            throw Error(`Script execution failed. ExecutionEngine state = FAULT. ${response.exception}`);
        }
        return neon_core_1.u.BigInteger.fromDecimal(response.gasconsumed, 0);
    }
    catch (e) {
        throw new Error(`Failed to get system fee. ${e}`);
    }
}
exports.getSystemFee = getSystemFee;
/**
 * Set the validUntilBlock field on a transaction
 *
 * If `blocksTillExpiry` is provided then the value is used.
 * If `blocksTillExpiry` is not provided, or the value exceeds the maximum allowed,
 * then the field is automatically set to the maximum allowed by the network.
 * @param transaction - the transaction to set the expiry field on
 * @param config -
 * @param blocksTillExpiry - number of blocks from the current chain height until the transaction is no longer valid
 */
async function setBlockExpiry(transaction, config, blocksTillExpiry) {
    let blockLifeSpan = neon_core_1.tx.Transaction.MAX_TRANSACTION_LIFESPAN;
    if (blocksTillExpiry &&
        !(blocksTillExpiry > neon_core_1.tx.Transaction.MAX_TRANSACTION_LIFESPAN))
        blockLifeSpan = blocksTillExpiry;
    const rpcClient = new neon_core_1.rpc.RPCClient(config.rpcAddress);
    transaction.validUntilBlock =
        (await rpcClient.getBlockCount()) + blockLifeSpan - 1;
}
exports.setBlockExpiry = setBlockExpiry;
/**
 * Add system and network fees to a transaction.
 * Validates that the source Account has sufficient balance
 *
 * Note: Witnesses must be present on the transaction. If no witnesses are
 * present a temporary single signature account witness will be used for
 * fee calculation. For fee calculation using a multi signature account you
 * must add the witness yourself. See TransactionBuilder.addEmptyWitnesses()
 * for reference how this could be done.
 * @param transaction - the transaction to add network and system fees to
 * @param config -
 */
async function addFees(transaction, config) {
    if (config.networkFeeOverride && config.prioritisationFee) {
        throw new Error("networkFeeOverride and prioritisationFee are mutually exclusive");
    }
    if (config.systemFeeOverride) {
        transaction.systemFee = config.systemFeeOverride;
    }
    else {
        transaction.systemFee = await getSystemFee(transaction.script, config, transaction.signers);
    }
    if (config.account === undefined)
        throw new Error("Cannot determine network fee and validate balances without an account in your config");
    if (config.networkFeeOverride) {
        transaction.networkFee = config.networkFeeOverride;
    }
    else {
        const rpcClient = new neon_core_1.rpc.RPCClient(config.rpcAddress);
        const txClone = new neon_core_1.tx.Transaction(transaction);
        if (txClone.witnesses.length < 1) {
            txClone.addWitness(new neon_core_1.tx.Witness({
                invocationScript: "",
                verificationScript: neon_core_1.u.HexString.fromBase64(config.account.contract.script).toString(),
            }));
        }
        transaction.networkFee = await (0, neon_api_1.smartCalculateNetworkFee)(txClone, rpcClient);
    }
    if (config.prioritisationFee) {
        transaction.networkFee = transaction.networkFee.add(neon_core_1.u.BigInteger.fromNumber(config.prioritisationFee));
    }
    const GAS = new nep17_1.GASContract(config);
    const gasBalance = await GAS.balanceOf(config.account.address);
    const requiredGAS = parseFloat(transaction.systemFee.add(transaction.networkFee).toDecimal(8));
    if (gasBalance < requiredGAS) {
        throw new Error(`Insufficient GAS. Required: ${requiredGAS} Available: ${gasBalance}`);
    }
}
exports.addFees = addFees;
/**
 * Deploy a smart contract
 * @param nef - A smart contract in Neo executable file format. Commonly created by a NEO compiler and stored as .NEF on disk
 * @param manifest - the manifest corresponding to the smart contract
 * @param config -
 */
async function deployContract(nef, manifest, config) {
    const builder = new neon_core_1.sc.ScriptBuilder();
    builder.emitContractCall({
        scriptHash: neon_core_1.CONST.NATIVE_CONTRACT_HASH.ManagementContract,
        operation: "deploy",
        callFlags: neon_core_1.sc.CallFlags.All,
        args: [
            neon_core_1.sc.ContractParam.byteArray(neon_core_1.u.HexString.fromHex(nef.serialize(), true)),
            neon_core_1.sc.ContractParam.string(JSON.stringify(manifest.toJson())),
        ],
    });
    const transaction = new neon_core_1.tx.Transaction();
    transaction.script = neon_core_1.u.HexString.fromHex(builder.build());
    await setBlockExpiry(transaction, config, config.blocksTillExpiry);
    // add a sender
    if (config.account === undefined)
        throw new Error("Account in your config cannot be undefined");
    transaction.addSigner({
        account: config.account.scriptHash,
        scopes: "CalledByEntry",
    });
    await addFees(transaction, config);
    transaction.sign(config.account, config.networkMagic);
    const rpcClient = new neon_core_1.rpc.RPCClient(config.rpcAddress);
    return await rpcClient.sendRawTransaction(transaction);
}
exports.deployContract = deployContract;
/**
 * Get the hash that identifies the contract on the chain matching the specified NEF
 * @param sender - The sender of the transaction
 * @param nefChecksum - The checksum of the Neo Executable File. A NEF file is a smart contract commonly created by a NEO compiler and stored as .NEF on disk
 * @param contractName - The name as indicated in the manifest
 */
function getContractHash(sender, nefChecksum, contractName) {
    const assembledScript = new neon_core_1.sc.ScriptBuilder()
        .emit(neon_core_1.sc.OpCode.ABORT)
        .emitPush(sender)
        .emitPush(nefChecksum)
        .emitPush(contractName)
        .build();
    return neon_core_1.u.reverseHex(neon_core_1.u.hash160(assembledScript));
}
exports.getContractHash = getContractHash;
