"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.base642utf8 = exports.utf82base64 = exports.base642hex = exports.hex2base64 = void 0;
const crypto_js_1 = require("crypto-js");
function hex2base64(input) {
    return crypto_js_1.enc.Base64.stringify(crypto_js_1.enc.Hex.parse(input));
}
exports.hex2base64 = hex2base64;
function base642hex(input) {
    return crypto_js_1.enc.Base64.parse(input).toString(crypto_js_1.enc.Hex);
}
exports.base642hex = base642hex;
function utf82base64(input) {
    return crypto_js_1.enc.Utf8.stringify(crypto_js_1.enc.Base64.parse(input));
}
exports.utf82base64 = utf82base64;
function base642utf8(input) {
    return crypto_js_1.enc.Base64.parse(input).toString(crypto_js_1.enc.Utf8);
}
exports.base642utf8 = base642utf8;
