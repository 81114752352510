"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RPCClient = void 0;
const NeoServerRpcClient_1 = require("./clients/NeoServerRpcClient");
const clients_1 = require("./clients");
const PING_TIMEOUT = 2000;
class FullRpcClient extends (0, clients_1.TokenTrackerRpcMixin)((0, clients_1.ApplicationLogsRpcMixin)((0, NeoServerRpcClient_1.NeoServerRpcMixin)(clients_1.RpcDispatcher))) {
    get [Symbol.toStringTag]() {
        return `FullRpcClient(${this.url})`;
    }
}
/**
 * RPC Client model to query a NEO node. Contains built-in methods to query using RPC calls.
 */
class RPCClient extends FullRpcClient {
    /**
     * @param net - A url pointing to a NEO RPC node.
     * @param version - version of NEO node. Used to check if RPC methods have been implemented. it will default to DEFAULT_RPC found in CONST
     */
    constructor(net) {
        super(net);
        this.net = net;
        this.history = [];
        this.lastSeenHeight = 0;
        this._latencies = [];
    }
    get [Symbol.toStringTag]() {
        return `RPC Client(${this.net})`;
    }
    get latency() {
        if (this._latencies.length === 0) {
            return 99999;
        }
        return Math.floor(this._latencies.reduce((p, c) => p + c, 0) / this._latencies.length);
    }
    set latency(lat) {
        if (this._latencies.length > 4) {
            this._latencies.shift();
        }
        this._latencies.push(lat);
    }
    /**
     * Measures the latency using getBlockCount call. Returns the current latency. For average, call this.latency
     */
    async ping() {
        const timeStart = Date.now();
        try {
            const response = await this.getBlockCount();
            this.lastSeenHeight = response;
            const newPing = Date.now() - timeStart;
            this.latency = newPing;
            return newPing;
        }
        catch (err) {
            this.latency = PING_TIMEOUT;
            return PING_TIMEOUT;
        }
    }
}
exports.RPCClient = RPCClient;
exports.default = RPCClient;
