"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serializeArrayOf = exports.deserializeArrayOf = exports.getSerializedSize = void 0;
const convert_1 = require("./convert");
const HexString_1 = require("./HexString");
/**
 * Calculates the byte size of any supported input following NEO's variable int format.
 */
function getSerializedSize(value) {
    switch (typeof value) {
        case "number": {
            if (value < 0xfd)
                return 1;
            else if (value <= 0xffff)
                return 3;
            else
                return 5;
        }
        case "object": {
            if (value instanceof HexString_1.HexString) {
                const size = value.byteLength;
                return getSerializedSize(size) + size;
            }
            else if (Array.isArray(value)) {
                let size = 0;
                if (value.length > 0) {
                    if (typeof value[0].size === "number" &&
                        typeof value[0].serialize === "function") {
                        size = value
                            .map((item) => item.size)
                            .reduce((prev, curr) => prev + curr, 0);
                    }
                }
                return getSerializedSize(value.length) + size;
            }
            // do not break here so we fall through to the default
        }
        default:
            throw new Error("Unsupported value type: " + typeof value);
    }
}
exports.getSerializedSize = getSerializedSize;
function deserializeArrayOf(type, ss) {
    const output = [];
    const len = ss.readVarInt();
    for (let i = 0; i < len; i++) {
        output.push(type(ss));
    }
    return output;
}
exports.deserializeArrayOf = deserializeArrayOf;
function serializeArrayOf(prop) {
    return ((0, convert_1.num2VarInt)(prop.length) +
        prop.map((p) => (typeof p === "string" ? p : p.serialize())).join(""));
}
exports.serializeArrayOf = serializeArrayOf;
