import React from "react"
import Header from "../Components/Header";
import QRCode from "react-qr-code";
import BlueReward from "./../../assets/blue_reward.gif"
import GreenReward from "./../../assets/green_reward.gif"

import BlueBackground from "./../../assets/blue_bg.png"
import GreenBackground from "./../../assets/green_bg.png"
import Neon, { rpc } from "@cityofzion/neon-js";

import { helpers } from '@cityofzion/props'
import Global from "../../Global"
import EventContext from "../../contexts/EventContext";

export default function Reward(props) {

    const { chestMetadata, debug } = props;

    const checkResultInterval = 1000
    const [count, setCount] = React.useState(0)

    const [isRewardPresented, setIsRewardPresented] = React.useState(false)
    const [runCheckResult, setRunCheckResult] = React.useState(true)
    const [chestResult, setChestResult] = React.useState(null)
    const SupportedAssets = Global.Assets;
    const {event} = React.useContext(EventContext)


    const chestConfigurations = {
        "green": {
            chestColor:"text-primary",
            rewardImage: GreenReward,
            rewardBackground: GreenBackground
        },
        "blue": {
            chestColor:"text-blue-600",
            rewardImage: BlueReward,
            rewardBackground: BlueBackground
        },
        "gold": {
            chestColor:"text-yellow-400",
        },
         "blacksmith": {
            chestColor:"text-black",
            rewardImage: GreenReward, 
            rewardBackground: GreenBackground 
        }
    }

    const chestColor = () => {
        var config = chestConfigurations[chestMetadata.chestColor]
        if (config) {
            return config.chestColor
        }
        return "text-gray-600"
    }

    const rewardImage = () => {
        var config = chestConfigurations[chestMetadata.chestColor]
        if (config) {
            return config.rewardImage
        }
        return GreenReward
    }

    const rewardBackground = () => {
        var config = chestConfigurations[chestMetadata.chestColor]
        if (config) {
            return config.rewardBackground
        }
        return BlueBackground
    }

    const checkResult = async () => {
        setCount(count => count + 1)

        const CHEST_ID = chestMetadata.id

        //get the starting block height
        const node = Neon.create.rpcClient('https://mainnet1.neo.coz.io:443')
        let lastBlockHeight = await node.getBlockCount()

        while (true) {

            //get the current block height
            const newHeight = await node.getBlockCount()

            //iterate over each new block since the last time we checked
            while (lastBlockHeight < newHeight) {
                lastBlockHeight = lastBlockHeight + 1 //block height 1658727 has a key so we can use it to test

                //get the block and iterate over all the transactions
                const block = await node.getBlock(lastBlockHeight - 1, true)
                for (let tx of block.tx) {
                    // console.log(tx)
                    const log = await node.getApplicationLog(tx.hash)
                    const notifs = log.executions[0].notifications
                    const results = log.executions[0].stack

                    //for each notificcation, check if it is from the chests and was an open event
                    notifs.forEach((n) => {
                        if (n.contract === '0xb94e721f5425ba1d8830ad752e50e0474f989da5' &&
                            n.eventname === 'ChestOpened') {
                            const notifParams = helpers.formatter(n.state)
                            console.log(notifParams)
                        
                            // check if this is the correct chest id here
                            if (notifParams[0] === CHEST_ID) {
                                // if this is the correct chest_id, look at the tx result to get what was looted
                                let pretty = helpers.formatter(results[0])
                                pretty.scriptHash = Neon.u.reverseHex(Neon.u.str2hexstring(pretty.scriptHash))
                                console.log(`results: ${JSON.stringify(pretty)}`) //this is the result of the transaction
                                setChestResult(pretty)
                            }
                        }
                    })
                }
            }
            await helpers.sleep(1000)
        }
    }

    React.useEffect(() => {
        checkResult()
    }, [])

    const reset = () => {
        setCount(0)
        setIsRewardPresented(false)
        setRunCheckResult(true)
    }

    return (
        <div className="absolute inset-0 w-full h-full flex flex-col">


            {/* <div className="absolute top-0 right-0 m-3 z-50">
                <button onClick={reset} className="p-3 bg-gray-600 text-white text-sm font-semibold uppercase">Reset</button>
            </div> */}

            <div className="py-3 border-b flex items-center justify-center">
                <div className="flex items-center">
                    <svg width="140" height="42" viewBox="0 0 140 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M59.4299 13.2299C60.8999 14.5249 61.6349 16.4499 61.6349 18.9349V31.8499H58.4149V20.0549C58.4149 17.9549 57.9599 16.4149 57.0499 15.4699C56.1399 14.5249 54.9499 14.0699 53.4449 14.0699C52.3899 14.0684 51.3484 14.3079 50.3999 14.7699C49.4187 15.295 48.6149 16.0987 48.0899 17.0799C47.4599 18.1299 47.1799 19.4949 47.1799 21.1049V31.8499H43.9249V12.2499L46.8999 13.4749L46.9699 14.8049C47.586 13.6265 48.5688 12.6805 49.7699 12.1099C51.0114 11.505 52.3793 11.2051 53.7599 11.2349C56.0466 11.2349 57.9366 11.8999 59.4299 13.2299Z" fill="#000033" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M69.405 22.9602H84.63C84.7404 22.2067 84.7989 21.4466 84.805 20.6852C84.8271 19.0275 84.4679 17.3869 83.755 15.8902C83.0679 14.4803 81.9856 13.3007 80.64 12.4952C79.1564 11.6257 77.4589 11.1892 75.74 11.2352C72.695 11.2352 70.385 12.1802 68.705 14.0352C67.025 15.8902 66.22 18.4452 66.22 21.7352C66.22 25.0252 67.06 27.6152 68.81 29.4702C70.56 31.3252 72.94 32.2702 75.985 32.2702C78.085 32.2702 79.835 31.8852 81.165 31.1152C82.532 30.3663 83.651 29.2352 84.385 27.8602L81.69 26.4252C81.3384 27.401 80.6237 28.2034 79.695 28.6652C78.5351 29.2071 77.2647 29.4707 75.985 29.4352C74.13 29.4352 72.59 28.8402 71.435 27.7202C70.28 26.6002 69.58 25.0252 69.405 22.9602ZM69.4399 20.09C69.6849 18.165 70.3499 16.695 71.4349 15.645C72.5199 14.595 74.0249 14.07 75.7399 14.07C77.4549 14.07 78.8199 14.63 79.8349 15.68C80.8499 16.73 81.4799 18.2 81.6899 20.09H69.4399Z" fill="#000033" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M108.815 21.735C108.815 18.445 107.94 15.89 106.19 14.035C104.475 12.18 102.06 11.235 99.0151 11.235C95.9701 11.235 93.5551 12.18 91.8401 14.035C90.1251 15.89 89.2501 18.445 89.2501 21.735C89.2501 25.025 90.0901 27.615 91.8401 29.47C93.5901 31.325 95.9701 32.27 99.0151 32.27C102.06 32.27 104.44 31.325 106.19 29.47C107.94 27.615 108.815 25.025 108.815 21.735ZM92.4701 21.7348C92.4701 19.3548 93.0651 17.4648 94.2201 16.0998C95.3751 14.6998 96.9501 14.0348 99.0151 14.0348C101.08 14.0348 102.69 14.7348 103.845 16.0998C105 17.4648 105.56 19.3198 105.56 21.7348C105.56 24.1498 105 26.0398 103.845 27.4048C102.69 28.7698 101.045 29.4698 99.0151 29.4698C96.9851 29.4698 95.3751 28.7698 94.2201 27.4048C93.0651 26.0398 92.4701 24.1148 92.4701 21.7348Z" fill="#000033" />
                        <path d="M0 7.35V35.455L18.27 42V13.65L37.975 6.405L20.09 0L0 7.35Z" fill="#00E599" />
                        <path d="M19.7401 14.6999V29.8549L37.9751 36.3649V7.94495L19.7401 14.6999Z" fill="#00AF92" />
                        <line x1="125.158" y1="10.0225" x2="125.158" y2="33.2615" stroke="#000033" strokeLinecap="round" />
                    </svg>
                    <div>
                        <p className="font-bold">{event.name}</p>
                    </div>
                </div>
            </div>


            <div className=" w-full mx-auto relative flex items-center justify-center h-full ">

                <div className="h-full flex items-center justify-center w-1/2 relative">
                    <img className="object-cover absolute top-0 left-0 w-full h-full" src={rewardBackground()} />
                    <img src={rewardImage()} />
                </div>

                <div className="w-1/2 h-full tex-center flex flex-col justify-center p-6">
                    <p className={`text-xl font-bold ${chestColor()}`}>Congratulations!</p>
                    <p className="mt-3 font-bold">Well done, your Puppet successfully opened the <span className="capitalize">{chestMetadata.chestColor}</span> Chest.</p>
{
    chestResult !== null ?
    <div className="rounded-lg shadow-lg mt-6 border">
                        <div className="flex font-bold items-center p-3">
                            <div className="w-12 h-12 rounded overflow-hidden mr-3 flex items-center justify-center bg-slate-900">

                                {
                                    SupportedAssets["0x" + chestResult.scriptHash] ?
                                        <img className="w-full h-full object-cover" src={SupportedAssets["0x" + chestResult.scriptHash].icon_url} />
                                        : null
                                }
                            </div>
                            <p>{ SupportedAssets["0x" + chestResult.scriptHash].name}</p>
                            <p className="ml-auto">
                                {
                                    chestResult.type === "NEP-17" ?
                                    <span>{chestResult.quantity / Math.pow(10,SupportedAssets["0x" + chestResult.scriptHash].decimals)}</span>
                                    :
                                    <span>{chestResult.quantity}</span>
                                }
                                
                            </p>
                        </div>
                    </div>
                    : null
}
                    



                </div>

            </div>
        </div>
    )
}