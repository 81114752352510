import React from "react"
export const AccountContext = React.createContext({
    account: {},
    isEligible: false,
    setIsEligible: ()=> {},
    chestKey: "", //blue, green, gold
    setChestKey: () => {},
    puppetTokenId: null, 
    setPuppetTokenId: () => {},
    goldPuppetTokenId: null, 
    setGoldPuppetTokenId: () => {},
    isEligibleForGoldChest: false,
    setIsEligibleForGoldChest: ()=> {},

    balances:[],
    nep11Assets:[],
    setBalances:() => {},
    setNep11Balances:() => {},
})

export default AccountContext