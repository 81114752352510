import { Routes, Switch, Route } from "react-router-dom";
import Home from "./views/Home";
import { useNavigate, useSearchParams } from "react-router-dom";
import { default as Neon } from "@cityofzion/neon-js";
import AssetInfo from "./views/AssetInfo";
import React, { useState } from "react";
import AccountContext from "./contexts/AccountContext";

import Result from "./views/Result";
import { WalletConnectProvider } from "@cityofzion/wallet-connect-sdk-react";
import { Buffer } from 'buffer';
import Wallet from "./views/Wallet/Wallet";
import ChestApp from "./views/ChestApp/ChestApp";
import AssetDetail from "./views/AssetDetail/AssetDetail";
import Connected from "./views/Connected/Connected";
import GoldKeyResult from "./views/GoldKeyResult/GoldKeyResult";
import Reward from "./views/Reward";
import Nep11Detail from "./views/Nep11Detail";
import {Chest,types} from '@cityofzion/props'
import Global from "./Global";
import EventContext from "./contexts/EventContext";
import {WalletConnectContextProvider, useWalletConnect} from "./contexts/WalletConnectContext";


// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

function App() {
  const puppetContract = "0x76a8f8a7a901b29a33013b469949f4b08db15756" //mainnet
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  //test key with empty wallet = Kx8zdTKeptTCf1gQTgokHbDgNwDyQxZ8bT6UZkb4c2urmvN2KjmZ
  const [account, setAccount] = useState(null)
  const [chestKey, setChestKey] = useState(null)
  const [puppetTokenId, setPuppetTokenId] = useState(null)
  const [goldPuppetTokenId, setGoldPuppetTokenId] = useState(null)

  const wif = searchParams.get("wif") || localStorage.getItem("wif")
  const [error, setError] = useState(null)
  const [isEligible, setIsEligible] = useState(false)
  const [isEligibleForGoldChest, setIsEligibleForGoldChest] = useState(false)
  const [balances, setBalances] = React.useState(null)
  const [nep11Balances, setNep11Balances] = React.useState(null)

  const accountContextValue = { account, chestKey, setChestKey, puppetTokenId, setPuppetTokenId, goldPuppetTokenId, setGoldPuppetTokenId, isEligible, setIsEligible, balances, setBalances, nep11Balances,setNep11Balances, isEligibleForGoldChest, setIsEligibleForGoldChest };
  const [wcClient, setWcClient] = useState(null)
  const [isConnected, setIsConnected] = useState(false)
  const [session, setSession] = useState(null)
  const [proposal, setProposal] = useState(null)
  const [chestMetadata, setChestMetadata] = useState(null)
  const [loading, setLoading] = useState(false)
  const [finishChecking, setFinishChecking] = useState(false)
  const [finishCheckingGoldKey, setFinishCheckingGoldKey] = useState(false)
  
  // const walletConnectContextValue = { wcClient, setWcClient, isConnected, setIsConnected,proposal, setProposal, session, setSession, chestMetadata, setChestMetadata, loading, setLoading };
  const chestId = searchParams.get("chest_id")
  const debug = searchParams.get("debug")

  const eventId = searchParams.get("eid") || localStorage.getItem("eid")

  const Events = Global.Events
  const [event, setEvent] = useState(Events["consensus"]) //default event to consensus for backward compatibility
  
  const walletConnectCtx = useWalletConnect()

  const eventContextValue = {event}
  
  const chests = {
    "green": "\u0005", //production for consensus 2022
    "blue": "\u0004", //production for consensus 2022
    "gold": "\u0006", //production for consensus 2022
    "blacksmith":"\x07", //for token2049
  }
  const pathname = window.location.pathname


  React.useEffect(()=> {
    console.log("walletConnectCtx.sessions", walletConnectCtx.sessions)
  },[walletConnectCtx.sessions])

  React.useEffect(() => {
    //becase we have Chest app and wallet app in one, we make sure here that we don't run any wallet related code
    //when opening /chest
    if (pathname == "/chest" || pathname === "/reward") {
      return;
    }

    if (wif === null) {
      setAccount(null)
      return
    }

    const isWif = Neon.is.wif(wif);
    if (isWif) {
      const neoAccount = Neon.create.account(wif)
      setAccount(neoAccount)
      localStorage.setItem("wif", wif)
    } else {
      setError("Invalid key")
      localStorage.removeItem("wif")
      setAccount(null)
    }

  }, [wif, pathname])

  React.useEffect(()=> {
    if (eventId !== null) {
      const e = Events[eventId] 
      if (e !== undefined) {
        setEvent(e)
        localStorage.setItem("eid", eventId)
      }
    } else {
      
    }
  },[])

  React.useEffect(()=> {

    if (window.location.pathname == "/chest" || window.location.pathname === "/reward") {
      return;
    }
    
    if (account === null) {
      return;
    }

     //if event is done we redirect all users to /wallet
     const today = new Date()
     if (event.ends_at < today) {
      navigate("/wallet", {replace:true})
     }else {
       console.log("stil valid")
     }
  },[account, event])

  const checkIsEligible = async (key, tokenId) => {
    const targetNetwork = types.NetworkOption.MainNet
    const chestId =  chests[key] // we check eligible based on the key that a user has 
    const puppetId = tokenId

    const chest = await new Chest({
      network: targetNetwork
    })
    await chest.init()
  
    const eligible = await chest.isPuppetEligible(chestId, puppetId)
    console.log("eligible for",key,"chest ", eligible)
    setFinishChecking(true)
    setIsEligible(eligible)
  }

  const checkIsEligibleForGoldChest = async (tokenId) => {
    const targetNetwork = types.NetworkOption.MainNet
    const chestId =  chests["gold"] // we check eligible based on the key that a user has 
    const puppetId = tokenId

    const chest = await new Chest({
      network: targetNetwork
    })
    await chest.init()
  
    const eligible = await chest.isPuppetEligible(chestId, puppetId)
    console.log("eligible for gold chest ", eligible)
    setFinishCheckingGoldKey(true)
    setIsEligibleForGoldChest(eligible)
  }

  //1. Start here. Load both nep11 and nep17 assets of this account
  React.useEffect(() => {
    if (account !== null) {
      console.log("loadAssets of", account.address)
      loadAssets()
    }
  }, [account])

  //4. when we are done checking eligibility for a green or blue key
  //if user is eligible to open either green or blue chest then we initialize WalletConnect
  React.useEffect(() => {

    if (finishChecking == false) {
      return
    }

    //if user is not eligible we redirect them to /wallet
    if (isEligible == false && chestKey !== null && puppetTokenId !== null) {
      navigate("/wallet", {replace:true})
    }

  }, [finishChecking, isEligible, chestKey, puppetTokenId])

  //3. when we found either green or blue key, we then check if this acccount is eligible to open the respective chest
  //green key to open geen chest, blue key to open blue chest
  React.useEffect(()=>{
    if (chestKey !== null && puppetTokenId !== null) {
      console.log("checking", account.address, "with", chestKey, "key and tokenId = ", puppetTokenId)
      checkIsEligible(chestKey, puppetTokenId)
    }

  }, [chestKey, puppetTokenId])

  //3. when we found gold key, we check if this account is eligible to open a gold chest
  React.useEffect(()=>{
    //after we found a golden puppet token id we then check if a user is eligible to open the gold chest.
    if (goldPuppetTokenId !== null) {
      console.log("checking", account.address, "with gold key and goldPuppetTokenId = ", goldPuppetTokenId)
      checkIsEligibleForGoldChest(goldPuppetTokenId)
    }

  }, [goldPuppetTokenId])


  React.useEffect(()=>{

    if (finishChecking === false || finishCheckingGoldKey == false) {
      return
    }

    if (isEligible == false && isEligibleForGoldChest == false) {
      //so if a user is not eligle for both of the key we then redirect them to wallet screen
      navigate("/wallet", {replace:true})
    }

  }, [finishChecking, finishCheckingGoldKey, isEligible, isEligibleForGoldChest])

 

  React.useEffect(() => {

    if (finishCheckingGoldKey == false) {
      return
    }
    if (isEligibleForGoldChest === true && goldPuppetTokenId !== null) {
      console.log("initializing walletconnect")
      // initWalletConnect()
    } 

  }, [finishCheckingGoldKey, isEligibleForGoldChest, goldPuppetTokenId])


  const loadAssets = async () => {
      const fetchData = url => fetch(url).then(r => r.json()); // 1
      const nep11AssetsUrl = `https://api.ghostmarket.io/api/v1/assets?chain=n3&owner=${account.address}`
      const balancesUrl = `https://dora.coz.io/api/v1/neo3/mainnet/balance/${account.address}`

      const [nep11Assets, assets] = await Promise.all([
          fetchData(nep11AssetsUrl),
          fetchData(balancesUrl)
        ]);

        setNep11Balances(nep11Assets)
        setBalances(assets)
  }

  //2. when we get nep11 balances
  React.useEffect(()=>{
    if (nep11Balances === null) return
       
    //if user doesn't have any nep11 meaning that this wallet doesn't have puppet
    if (nep11Balances.assets == null) {
      console.log("nep11Balances",nep11Balances)
      return
    }

    // console.log("nep11Balances",nep11Balances)
    
    let blackSmithKey = nep11Balances.assets.filter((a)=> (a.nft.contract === puppetContract && a.nft.nft_metadata.attributes.some(attribute => (attribute.name === "traits.trade" && (attribute.value === "blacksmith")))))
    
    if (blackSmithKey.length > 0) {

      const asset = blackSmithKey[0];
      const symbol = asset.nft.symbol;
      const chain = asset.nft.chain;
      const contract = asset.nft.contract;
      
      const metaData = asset.nft.nft_metadata;
      var regex = /\#(.*?)\./
      //looking for token id inside here
      //description: Puppet NFT #4891. 9 of 10000 from the Paper Puppets(3) epoch
      const description = metaData.description
      var matched = regex.exec(description);
      const tokenId = matched[1]

      let key = metaData.attributes.filter(attribute => { return attribute.name === "traits.trade" && (attribute.value === "blacksmith")})
      const value = key[0].value
      console.log("blackSmithKey", key, value, tokenId)
      setPuppetTokenId(tokenId)
      setChestKey(value);
    }


    //look through nep11 balances of this account to find key
    let blueOrGreenKey = nep11Balances.assets.filter((a)=> (a.nft.contract === puppetContract && a.nft.nft_metadata.attributes.some(attribute => (attribute.name === "traits.color" && (attribute.value === "green" || attribute.value === "blue")))))
    let goldKey = nep11Balances.assets.filter((a)=> (a.nft.contract === puppetContract && a.nft.nft_metadata.attributes.some(attribute => (attribute.name === "traits.color" && (attribute.value === "gold")))))

    //if we found either green or blue key
    if (blueOrGreenKey.length > 0) {
      const asset = blueOrGreenKey[0];
      const symbol = asset.nft.symbol;
      const chain = asset.nft.chain;
      const contract = asset.nft.contract;
      
      const metaData = asset.nft.nft_metadata;
      var regex = /\#(.*?)\./
      //looking for token id inside here
      //description: Puppet NFT #4891. 9 of 10000 from the Paper Puppets(3) epoch
      const description = metaData.description
      var matched = regex.exec(description);
      const tokenId = matched[1]

      let key = metaData.attributes.filter(attribute => { return attribute.name === "traits.color" && (attribute.value === "green" || attribute.value === "blue")})
      const color = key[0].value
      console.log("blue or green", key, color, tokenId)
      setPuppetTokenId(tokenId)
      setChestKey(color);
    }

    //if we found gold key
    if (goldKey.length > 0) {

      const asset = goldKey[0];
      const symbol = asset.nft.symbol;
      const chain = asset.nft.chain;
      const contract = asset.nft.contract;
      
      const metaData = asset.nft.nft_metadata;
      var regex = /\#(.*?)\./
      //looking for token id inside here
      //description: Puppet NFT #4891. 9 of 10000 from the Paper Puppets(3) epoch
      const description = metaData.description
      var matched = regex.exec(description);
      const tokenId = matched[1]
      
      let key = metaData.attributes.filter(attribute => { return attribute.name === "traits.color" && (attribute.value === "gold")})
      const color = key[0].value
      console.log("gold", key, color, tokenId)
      setGoldPuppetTokenId(tokenId)
    }

  }, [nep11Balances])

 

  const resetConnection = () => {
    setFinishChecking(false)
    setSession(null)
    setProposal(null)
    setChestMetadata(null)
    setIsConnected(false)
  }

  const chestWcOptions = {
    projectId: '456fc5b514af312d6a87fb8a755f9580',
    relayUrl: "wss://relay.walletconnect.com", 
    metadata: {
      name: "Chest",
      id: chests[chestId], //e.g. mapping green to \u0002
      chestColor: chestId,
      description: "Chest App", // description to be shown on the wallet
      url: "https://chest.neo.org/", // url to be linked on the wallet
      icons: ["https://neo3.azureedge.net/images/logo%20files-dark.svg"], // icon to be shown on the wallet
    },
  };


  return (
      <EventContext.Provider value={eventContextValue}>
      <AccountContext.Provider value={accountContextValue}>
        <WalletConnectProvider autoManageSession={true} options={chestWcOptions}>
          <Routes>
            <Route path="/reward" element={<Reward chestMetadata={chestWcOptions.metadata} debug={debug} />} />
            <Route path="/chest" element={<ChestApp chestMetadata={chestWcOptions.metadata} debug={debug} />} />
            <Route path="/result" element={<Result />} />
            <Route path="/goldresult" element={<GoldKeyResult />} />
            <Route path="/connected" element={<Connected onDisconnect={resetConnection} />} />
            <Route path="/asset" element={<AssetInfo />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/assets/:id" element={<AssetDetail />} />
            <Route path="/nep11/:contract/:id" element={<Nep11Detail />} />
            <Route exact path="/" element={<Home />} />
          </Routes>
        </WalletConnectProvider>
      </AccountContext.Provider>
      </EventContext.Provider>

  );
}

export default App;
