"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MethodToken = void 0;
const u_1 = require("../u");
const CallFlags_1 = require("./CallFlags");
class MethodToken {
    constructor(obj) {
        const { hash = "", method = "", parametersCount = 0, hasReturnValue = false, callFlags = CallFlags_1.CallFlags.None, } = obj;
        this.hash = hash;
        this.method = method;
        this.parametersCount = parametersCount;
        this.hasReturnValue = hasReturnValue;
        this.callFlags = callFlags;
    }
    static fromJson(json) {
        return new MethodToken({
            hash: json.hash,
            method: json.method,
            parametersCount: json.parameterscount,
            hasReturnValue: json.hasreturnvalue,
            callFlags: json.callflags,
        });
    }
    static fromStream(reader) {
        const hash = reader.read(20);
        const method = (0, u_1.hexstring2str)(reader.readVarBytes());
        if (method.startsWith("_"))
            throw new Error("MethodToken deserialization failure - method cannot start with '_'");
        const parametersCount = Buffer.from(reader.read(2), "hex").readUInt16LE();
        const hasReturnValue = reader.read(1) !== "00";
        const flags = Number.parseInt(reader.read(1), 16);
        return new MethodToken({
            hash: hash,
            method: method,
            parametersCount: parametersCount,
            hasReturnValue: hasReturnValue,
            callFlags: flags,
        });
    }
    toJson() {
        return {
            hash: this.hash,
            method: this.method,
            parameterscount: this.parametersCount,
            hasreturnvalue: this.hasReturnValue,
            callflags: this.callFlags,
        };
    }
    get size() {
        return this.serialize().length;
    }
    serialize() {
        let out = "";
        out += this.hash;
        out += (0, u_1.num2VarInt)(this.method.length);
        out += (0, u_1.str2hexstring)(this.method);
        out += (0, u_1.num2hexstring)(this.parametersCount, 2, true);
        out += this.hasReturnValue ? "01" : "00";
        out += (0, u_1.num2hexstring)(this.callFlags);
        return out;
    }
    export() {
        return {
            hash: this.hash,
            method: this.method,
            parametersCount: this.parametersCount,
            hasReturnValue: this.hasReturnValue,
            callFlags: this.callFlags,
        };
    }
}
exports.MethodToken = MethodToken;
exports.default = MethodToken;
