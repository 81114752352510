import { formatJsonRpcError, } from '@json-rpc-tools/utils';
import SignClient from '@walletconnect/sign-client';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState, } from 'react';
export const WalletConnectContext = React.createContext({});
export const WalletConnectContextProvider = ({ options, children }) => {
    const [signClient, setSignClient] = useState(undefined);
    const [sessionProposals, setSessionProposals] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [requests, setRequests] = useState([]);
    const [onRequestCallback, setOnRequestCallback] = useState(undefined);
    const [autoAcceptCallback, setAutoAcceptCallback] = useState(undefined);
    const init = useCallback(async () => {
        setSignClient(await SignClient.init(options));
    }, []);
    useEffect(() => {
        init();
    }, [init]);
    const loadSessions = useCallback(async () => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        setSessions(signClient.session.values);
        setInitialized(true);
    }, [signClient]);
    // ---- MAKE REQUESTS AND SAVE/CHECK IF APPROVED ------------------------------//
    const onRequestListener = useCallback((listener) => {
        setOnRequestCallback(() => listener);
    }, []);
    const autoAcceptIntercept = useCallback((listener) => {
        setAutoAcceptCallback(() => listener);
    }, []);
    const findSessionByTopic = useCallback((topic) => {
        return sessions.find((session) => session.topic === topic);
    }, [JSON.stringify(sessions)]);
    const makeRequest = useCallback(async (requestEvent) => {
        var _a;
        const foundSession = findSessionByTopic(requestEvent.topic);
        const ns = Object.values((_a = foundSession === null || foundSession === void 0 ? void 0 : foundSession.namespaces) !== null && _a !== void 0 ? _a : [])[0];
        const acc = ns === null || ns === void 0 ? void 0 : ns.accounts[0];
        if (!acc) {
            throw new Error('There is no Account');
        }
        const [namespace, reference, address] = acc.split(':');
        const chainId = `${namespace}:${reference}`;
        if (!onRequestCallback) {
            throw new Error('There is no onRequestCallback');
        }
        return await onRequestCallback(address, chainId, requestEvent);
    }, [findSessionByTopic]);
    const respondRequest = useCallback(async (topic, response) => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        await signClient.respond({ topic, response });
    }, [signClient]);
    const subscribeToEvents = useCallback(() => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        signClient.events.removeAllListeners();
        signClient.on("session_proposal", (proposal) => {
            setSessionProposals((old) => [...old, proposal]);
        });
        signClient.on('session_request', async (requestEvent) => {
            var _a;
            try {
                if (autoAcceptCallback) {
                    let address = undefined;
                    let chainId = undefined;
                    const foundSession = findSessionByTopic(requestEvent.topic);
                    const ns = Object.values((_a = foundSession === null || foundSession === void 0 ? void 0 : foundSession.namespaces) !== null && _a !== void 0 ? _a : [])[0];
                    const acc = ns === null || ns === void 0 ? void 0 : ns.accounts[0];
                    if (acc) {
                        const [namespace, reference, addr] = acc.split(':');
                        address = addr;
                        chainId = `${namespace}:${reference}`;
                    }
                    const autoAccepted = autoAcceptCallback(address, chainId, requestEvent);
                    if (autoAccepted) {
                        const response = await makeRequest(requestEvent);
                        await respondRequest(requestEvent.topic, response);
                        return;
                    }
                }
                setRequests((old) => [...old.filter((i) => i.id !== requestEvent.id), requestEvent]);
            }
            catch (e) {
                const response = formatJsonRpcError(requestEvent.id, e.message);
                await respondRequest(requestEvent.topic, response);
            }
        });
        signClient.on('session_delete', () => {
            if (!signClient) {
                throw new Error('Client is not initialized');
            }
            setSessions(signClient.session.values);
        });
    }, [
        makeRequest,
        respondRequest,
        signClient,
        findSessionByTopic
    ]);
    useEffect(() => {
        if (signClient) {
            subscribeToEvents();
            loadSessions();
        }
    }, [signClient, subscribeToEvents, loadSessions]);
    const onURI = async (data) => {
        try {
            const uri = typeof data === 'string' ? data : '';
            if (!uri)
                return;
            if (!signClient) {
                throw new Error('Client is not initialized');
            }
            await signClient.pair({ uri });
        }
        catch (error) {
            throw new Error('client Pair Error');
        }
    }; // this should not be a callback because it would require the developer to put it as dependency
    const getPeerOfRequest = async (requestEvent) => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        const { peer } = await signClient.session.get(requestEvent.topic);
        return peer;
    }; // this should not be a callback because it would require the developer to put it as dependency
    const approveSession = async (proposal, accountsAndChains, namespacesWithoutAccounts) => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        if (typeof accountsAndChains === 'undefined') {
            throw new Error('Accounts is undefined');
        }
        const accounts = accountsAndChains.map((acc) => `${acc.chain}:${acc.address}`);
        const chains = accountsAndChains.map((acc) => acc.chain);
        const namespaces = Object.keys(namespacesWithoutAccounts).reduce((result, key) => {
            result[key] = Object.assign(Object.assign({}, namespacesWithoutAccounts[key]), { accounts, chains });
            return result;
        }, {});
        const { acknowledged } = await signClient.approve({
            id: proposal.id,
            namespaces
        });
        const session = await acknowledged();
        setSessionProposals((old) => old.filter((i) => i !== proposal));
        setSessions([session]);
    }; // this should not be a callback because it would require the developer to put it as dependency
    const rejectSession = async (proposal) => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        await signClient.reject({
            id: proposal.id,
            reason: {
                code: 1,
                message: 'rejected by the user'
            }
        });
        setSessionProposals((old) => old.filter((i) => i !== proposal));
    }; // this should not be a callback because it would require the developer to put it as dependency
    const disconnect = async (topic) => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        await signClient.disconnect({
            topic,
            reason: { code: 5900, message: 'USER_DISCONNECTED' },
        });
        setSessions(signClient.session.values);
    }; // this should not be a callback because it would require the developer to put it as dependency
    const removeFromPending = useCallback(async (requestEvent) => {
        setRequests(requests.filter((x) => x.id !== requestEvent.id));
    }, []);
    const approveRequest = async (requestEvent) => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        try {
            const response = await makeRequest(requestEvent);
            await signClient.respond({
                topic: requestEvent.topic,
                response,
            });
            await removeFromPending(requestEvent);
            return response;
        }
        catch (error) {
            await signClient.respond({
                topic: requestEvent.topic,
                response: formatJsonRpcError(requestEvent.id, 'Failed or Rejected Request'),
            });
            throw error;
        }
    }; // this should not be a callback because it would require the developer to put it as dependency
    const rejectRequest = async (requestEvent) => {
        if (!signClient) {
            throw new Error('Client is not initialized');
        }
        await signClient.respond({
            topic: requestEvent.topic,
            response: formatJsonRpcError(requestEvent.id, 'Failed or Rejected Request'),
        });
        await removeFromPending(requestEvent);
    }; // this should not be a callback because it would require the developer to put it as dependency
    const contextValue = {
        signClient,
        sessionProposals,
        initialized,
        sessions,
        setSessions,
        requests,
        init,
        onURI,
        getPeerOfRequest,
        approveSession,
        rejectSession,
        disconnect,
        approveRequest,
        rejectRequest,
        onRequestListener,
        autoAcceptIntercept,
    };
    return (React.createElement(WalletConnectContext.Provider, { value: contextValue }, children));
};
export const useWalletConnect = () => useContext(WalletConnectContext);
WalletConnectContextProvider.propTypes = {
    options: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
};