"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reverseArray = exports.hexXor = void 0;
const convert_1 = require("./convert");
const basic_1 = require("./basic");
/**
 * XORs two hexstrings
 *
 * @param str1 - HEX string
 * @param str2 - HEX string
 * @returns XOR output as a HEX string
 */
function hexXor(str1, str2) {
    (0, basic_1.ensureHex)(str1);
    (0, basic_1.ensureHex)(str2);
    if (str1.length !== str2.length) {
        throw new Error(`strings are disparate lengths. Inputs are of length ${str1.length} and ${str2.length}`);
    }
    const result = [];
    for (let i = 0; i < str1.length; i += 2) {
        result.push(
        // tslint:disable-next-line:no-bitwise
        parseInt(str1.substr(i, 2), 16) ^ parseInt(str2.substr(i, 2), 16));
    }
    return (0, convert_1.ab2hexstring)(result);
}
exports.hexXor = hexXor;
/**
 * Reverses an array.
 *
 * @example
 * reverseArray('abcd') = 'dcba'
 */
function reverseArray(arr) {
    if (typeof arr !== "object" || !arr.length) {
        throw new Error("reverseArray expects an array");
    }
    const result = new Array(arr.length);
    for (let i = 0; i < arr.length; i++) {
        result[i] = arr[arr.length - 1 - i];
    }
    return result;
}
exports.reverseArray = reverseArray;
