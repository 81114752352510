"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromMethodName = exports.generateInteropServiceCode = exports.InteropServiceCode = void 0;
const u_1 = require("../u");
var InteropServiceCode;
(function (InteropServiceCode) {
    InteropServiceCode["SYSTEM_CONTRACT_CALL"] = "627d5b52";
    InteropServiceCode["SYSTEM_CONTRACT_CALLNATIVE"] = "1af77b67";
    InteropServiceCode["SYSTEM_CONTRACT_CREATEMULTISIGACCOUNT"] = "6a33e909";
    InteropServiceCode["SYSTEM_CONTRACT_CREATESTANDARDACCOUNT"] = "cf998702";
    InteropServiceCode["SYSTEM_CONTRACT_GETCALLFLAGS"] = "95da3a81";
    InteropServiceCode["SYSTEM_CONTRACT_NATIVEONPERSIST"] = "2edbbc93";
    InteropServiceCode["SYSTEM_CONTRACT_NATIVEPOSTPERSIST"] = "44a15d16";
    InteropServiceCode["SYSTEM_CRYPTO_CHECKMULTISIG"] = "9ed0dc3a";
    InteropServiceCode["SYSTEM_CRYPTO_CHECKSIG"] = "56e7b327";
    InteropServiceCode["SYSTEM_ITERATOR_NEXT"] = "9c08ed9c";
    InteropServiceCode["SYSTEM_ITERATOR_VALUE"] = "f354bf1d";
    InteropServiceCode["SYSTEM_RUNTIME_BURNGAS"] = "c35a8cbc";
    InteropServiceCode["SYSTEM_RUNTIME_CHECKWITNESS"] = "f827ec8c";
    InteropServiceCode["SYSTEM_RUNTIME_GASLEFT"] = "1488d8ce";
    InteropServiceCode["SYSTEM_RUNTIME_GETADDRESSVERSION"] = "4c4992dc";
    InteropServiceCode["SYSTEM_RUNTIME_GETCALLINGSCRIPTHASH"] = "39536e3c";
    InteropServiceCode["SYSTEM_RUNTIME_GETENTRYSCRIPTHASH"] = "f9b4e238";
    InteropServiceCode["SYSTEM_RUNTIME_GETEXECUTINGSCRIPTHASH"] = "dbfea874";
    InteropServiceCode["SYSTEM_RUNTIME_GETINVOCATIONCOUNTER"] = "84271143";
    InteropServiceCode["SYSTEM_RUNTIME_GETNETWORK"] = "c5fba0e0";
    InteropServiceCode["SYSTEM_RUNTIME_GETNOTIFICATIONS"] = "274335f1";
    InteropServiceCode["SYSTEM_RUNTIME_GETRANDOM"] = "6bdea928";
    InteropServiceCode["SYSTEM_RUNTIME_GETSCRIPTCONTAINER"] = "2d510830";
    InteropServiceCode["SYSTEM_RUNTIME_GETTIME"] = "b7c38803";
    InteropServiceCode["SYSTEM_RUNTIME_GETTRIGGER"] = "e97d38a0";
    InteropServiceCode["SYSTEM_RUNTIME_LOG"] = "cfe74796";
    InteropServiceCode["SYSTEM_RUNTIME_NOTIFY"] = "95016f61";
    InteropServiceCode["SYSTEM_RUNTIME_PLATFORM"] = "b279fcf6";
    InteropServiceCode["SYSTEM_STORAGE_ASREADONLY"] = "764cbfe9";
    InteropServiceCode["SYSTEM_STORAGE_DELETE"] = "2f58c5ed";
    InteropServiceCode["SYSTEM_STORAGE_FIND"] = "df30b89a";
    InteropServiceCode["SYSTEM_STORAGE_GET"] = "925de831";
    InteropServiceCode["SYSTEM_STORAGE_GETCONTEXT"] = "9bf667ce";
    InteropServiceCode["SYSTEM_STORAGE_GETREADONLYCONTEXT"] = "f6b46be2";
    InteropServiceCode["SYSTEM_STORAGE_PUT"] = "e63f1884";
})(InteropServiceCode = exports.InteropServiceCode || (exports.InteropServiceCode = {}));
exports.default = InteropServiceCode;
function generateInteropServiceCode(methodName) {
    const hexMethodName = (0, u_1.str2hexstring)(methodName);
    return (0, u_1.sha256)(hexMethodName).slice(0, 8);
}
exports.generateInteropServiceCode = generateInteropServiceCode;
function fromMethodName(methodName) {
    const enumName = methodName.toUpperCase().replace(".", "_");
    if (enumName in InteropServiceCode) {
        return InteropServiceCode[enumName];
    }
    throw new Error("Method name not found in InteropServiceCode!");
}
exports.fromMethodName = fromMethodName;
