import React from "react"
import { useNavigate, createSearchParams } from 'react-router-dom';
import AccountContext from "../../contexts/AccountContext";
import Header from "../Components/Header";
import LoadingGIF from "./../../assets/icons8-rhombus-loader.gif"
import Loading from "../Components/Loading";
import { Chest, types } from '@cityofzion/props'
import { default as Neon } from "@cityofzion/neon-js";
import ConnectedBlacksmithChest from "./../../assets/connected_blacksmith_chest.png"
import { useWalletConnect } from "../../contexts/WalletConnectContext";

export default function Connected(props) {
    const navigate = useNavigate();

    const { account, chestKey, puppetTokenId, goldPuppetTokenId, isEligibleForGoldChest } = React.useContext(AccountContext)
    // const { wcClient, isConnected, chestMetadata, session, proposal } = React.useContext(WalletConnectContext)
    const [connecting, setConnecting] = React.useState(false)
    const [openingChest, setOpeningChest] = React.useState(false)
    const walletConnectCtx = useWalletConnect()

    const isConnected = walletConnectCtx.sessions.length > 0

    const firstSession = isConnected == true && walletConnectCtx.sessions && walletConnectCtx.sessions[0]
    const chestMetadata = firstSession && firstSession.peer.metadata

    const chestConfigurations = {
        "green": {
            chestColor: "text-primary",
            chestKeyColor: "text-primary",
        },
        "blue": {
            chestColor: "text-blue-600",
            chestKeyColor: "text-blue-600",
        },
        "gold": {
            chestColor: "text-yellow-400",
            chestKeyColor: "text-yellow-400",
        },
        "blacksmith": {
            chestColor: "text-black",
            chestKeyColor: "text-black",
        }
    }

    React.useEffect(() => {
        if (isConnected == false) {
            navigate("/", { replace: true })
        } else {

        }
    }, [isConnected])



    const chestColor = () => {
        if (chestMetadata === null || chestMetadata.chestColor === "") {
            return ""
        }

        var config = chestConfigurations[chestMetadata.chestColor]
        if (config) {
            return config.chestColor
        }
        return "text-gray-600"
    }

    const chestKeyColor = () => {
        if (chestKey === null) {
            return ""
        }
        var config = chestConfigurations[chestMetadata.chestColor]
        if (config) {
            return config.chestKey
        }
        return "text-gray-600"
    }

    const onOpenChest = () => {

        // if user already has a gold key init we just open gold chest
        if (chestMetadata.chestColor === "gold" && goldPuppetTokenId !== null && isEligibleForGoldChest === true) {
            console.log("opening gold chest")
            setOpeningChest(true)
            openGoldChest()
            return
        }

        if ((chestKey === "blue" || chestKey === "green" || chestKey === "blacksmith")) {
            console.log("opening", chestMetadata.chestColor, "chest")
            setOpeningChest(true)
            openChest()
            return;
        }

        alert(`Something is wrong here. chestKey= ${chestKey}, chestColor= ${chestMetadata.chestColor}`)
    }


    const openGoldChest = async () => {

        const targetNetwork = types.NetworkOption.MainNet
        const chestId = chestMetadata.id.toString()
        const puppetId = goldPuppetTokenId.toString() //use gold puppet token id
        console.log("chestId", chestId, typeof (chestId))
        console.log("puppetId", puppetId, typeof (puppetId))


        const userWallet = Neon.create.account(account._WIF) //make sure we new account here before sending it in
        console.log("userWallet", userWallet)
        const chest = await new Chest({
            network: targetNetwork
        })
        await chest.init()


        const isEligible = await chest.isPuppetEligible(chestId.toString(), puppetId.toString())

        if (isEligible === true) {

            try {

                const toOpenchestWallet = Neon.create.account(account._WIF.toString())
                console.log("opening chest", toOpenchestWallet)
                const res = await chest.lootChestWithPuppetVerified(chestId, puppetId, toOpenchestWallet)
                console.log("lootChestWithPuppetVerified ", res)

                setConnecting(false)
                setOpeningChest(false)

                navigate({
                    pathname: "/goldresult",
                    replace: true,
                })
                //disconnect as soon as we get the result
                // wcClient.disconnect({ topic: session.topic, reason: { code: 5900, message: 'USER_DISCONNECTED' }, })
                walletConnectCtx.disconnect(firstSession.topic)

            } catch (error) {
                // wcClient.disconnect({ topic: session.topic, reason: { code: 5900, message: 'USER_DISCONNECTED' }, })
                walletConnectCtx.disconnect(firstSession.topic)
                setOpeningChest(false)
                navigate("/", { replace: true })
                return

            }

        } else {
            // wcClient.disconnect({ topic: session.topic })
            walletConnectCtx.disconnect(firstSession.topic)
            setOpeningChest(false)
            navigate("/", { replace: true })
        }

    }

    const openChest = async () => {

        const targetNetwork = types.NetworkOption.MainNet
        const chestId = chestMetadata.id.toString()
        const puppetId = puppetTokenId.toString()
        console.log("chestId", chestId, typeof (chestId))
        console.log("puppetId", puppetId, typeof (puppetId))

        const userWallet = Neon.create.account(account._WIF) //make sure we new account here before sending it in
        console.log("userWallet", userWallet)
        const chest = await new Chest({
            network: targetNetwork
        })
        await chest.init()

        const isEligible = await chest.isPuppetEligible(chestId.toString(), puppetId.toString())

        if (isEligible === true) {

            try {

                const toOpenchestWallet = Neon.create.account(account._WIF.toString())
                console.log("opening chest", toOpenchestWallet)

                const res = await chest.lootChestWithPuppetVerified(chestMetadata.id, puppetTokenId, toOpenchestWallet)
                console.log("lootChestWithPuppetVerified ", res)


                const protocol = res[0].type === "NEP-17" ? "nep17" : "nep11"
                const contract = "0x" + res[0].scriptHash.toString();
                const tokenId = res[0].tokenId;
                const quantity = res[0].quantity

                setConnecting(false)
                setOpeningChest(false)

                navigate({
                    pathname: "/result",
                    replace: true,
                    search: createSearchParams({
                        chest: chestMetadata.chestColor,
                        protocol: protocol,
                        contract: contract,
                        token_id: tokenId,
                        quantity: quantity,
                    }).toString()
                })
                //disconnect as soon as we get the result
                walletConnectCtx.disconnect(firstSession.topic)

            } catch (error) {
                console.log("error", error)
                walletConnectCtx.disconnect(firstSession.topic)
                setOpeningChest(false)
                navigate("/", { replace: true })
                return
            }

        } else {
            walletConnectCtx.disconnect(firstSession.topic)
            setOpeningChest(false)
            navigate("/", { replace: true })
            return
        }
    }


    const disconnect = () => {
        walletConnectCtx.disconnect(firstSession.topic)
        props.onDisconnect()
    }


    return (
        <div className=" absolute inset-0 text-secondary">
            {
                connecting === true ?
                    <Loading></Loading>
                    :
                    null
            }
            <div className="p-3 container mx-auto relative flex flex-col h-full ">
                {
                    isConnected === true ?
                        <button onClick={(e) => disconnect()}>
                            <div className={`flex items-center ${chestColor()}`}>

                                <svg className="fill-current mr-1" width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.72 6C21.72 3.948 20.052 2.28 18 2.28H13.2V0H18C21.312 0 24 2.688 24 6C24 9.312 21.312 12 18 12H13.2V9.72H18C20.052 9.72 21.72 8.052 21.72 6ZM16.8 7.2H7.2V4.8H16.8V7.2ZM6 0H10.8V2.28H6C3.948 2.28 2.28 3.948 2.28 6C2.28 8.052 3.948 9.72 6 9.72H10.8V12H6C2.688 12 0 9.312 0 6C0 2.688 2.688 0 6 0Z" />
                                </svg>


                                <span className="font-semibold text-sm uppercase">CONNECTED</span>
                            </div>
                        </button>
                        : null
                }
                <div className="mt-6">
                    <Header></Header>
                </div>
                <div className="h-full flex flex-col items-center justify-center">

                    {
                        account !== null && openingChest === true ?
                            <div className="flex flex-col items-center justify-center">
                                <div className="mx-auto mb-3">
                                    <img className="h-12" src={LoadingGIF} />
                                </div>
                                <p className="font-bold text-lg">Looking for the right combination</p>
                            </div>
                            :
                            null
                    }

                    {
                        account !== null && openingChest == false && chestMetadata !== null ?
                            <div className="flex flex-col items-center justify-center p-6">
                                  {
                                        chestKey === "blacksmith" ?
                                            <img src={ConnectedBlacksmithChest} className="w-40 h-40 object-cover" />
                                            :
                                            <div className="bg-white p-6 rounded-xl shadow-xl mb-6 flex items-center justify-center">
                                    <div className="bg-white absolute z-40 rounded-full w-10 h-10 flex items-center justify-center">
                                        <svg className={` stroke-current ${chestColor()}`} width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.82819 3.71289L7.04735 7.93205C7.75061 8.63532 8.70444 9.0304 9.699 9.0304H16.1101C17.1046 9.0304 18.0585 8.63532 18.7617 7.93205L22.9809 3.71289" strokeWidth="1.88" strokeLinecap="square" />
                                            <path d="M15.369 11.5127V13.9719C15.369 15.5449 15.8636 17.0782 16.7829 18.3547L21.1395 24.4044" strokeWidth="1.88" strokeLinecap="square" />
                                            <path d="M10.4565 11.5127V13.9719C10.4565 15.5449 9.96186 17.0782 9.04259 18.3547L4.68597 24.4044" strokeWidth="1.88" strokeLinecap="square" />
                                            <path d="M4.15181 9.03075H1.68579" strokeWidth="1.88" strokeLinecap="square" />
                                            <path d="M23.9155 9.03075H21.4495" strokeWidth="1.88" strokeLinecap="square" />
                                            <path d="M10.8886 21.5991V26.0001" strokeWidth="1.88" strokeLinecap="square" />
                                            <path d="M14.6277 21.5991V26.0001" strokeWidth="1.88" strokeLinecap="square" />
                                            <circle cx="12.8753" cy="3.51888" r="2.26492" strokeWidth="1.88" strokeLinecap="square" />
                                        </svg>
                                    </div>

                                    <svg className={` fill-current ${chestColor()}`} width="81" height="106" viewBox="0 0 81 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M40.5 104L2 87.3091L27.4897 5.17922L40.5 2L50.8552 5.17922L79 87.3091L40.5 104Z" />
                                        <path d="M40.5 104L2 87.3091L27.4897 5.17922M40.5 104L79 87.3091L50.8552 5.17922M40.5 104V8.88831M50.8552 5.17922L40.5 8.88831M50.8552 5.17922L40.5 2L27.4897 5.17922M40.5 8.88831L27.4897 5.17922" stroke="white" strokeWidth="2" />
                                    </svg>
                                </div>
                                    }

                                
                                <p className="font-semibold uppercase">YOU ARE CONNECTED TO THE</p>
                                <p className="font-semibold uppercase">{chestMetadata.chestColor} CHEST</p>
                                <p className="text-center">Try and see if your {chestKey} puppet key has what it takes to unlock the chest</p>
                            </div>
                            :
                            null
                    }


                </div>

                <div className="mt-auto p-3 w-full">

                    {
                        account !== null && isConnected === true && openingChest == false ?
                            <div>
                                <button onClick={onOpenChest} disabled={connecting} className="disabled:bg-opacity-50 bg-primary w-full py-2 font-semibold text-white uppercase">Open Chest</button>
                            </div>
                            :
                            null
                    }

                </div>

            </div>
        </div>
    )
}
