"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNativeContractHash = exports.isMultisigContract = exports.isSignatureContract = void 0;
const u_1 = require("../u");
const OpCode_1 = require("./OpCode");
const buffer_1 = require("buffer");
const ScriptBuilder_1 = require("./ScriptBuilder");
const ContractParam_1 = require("./ContractParam");
const InteropServiceCode_1 = require("./InteropServiceCode");
/**
 * Check if the format of input matches that of a single signature contract
 */
function isSignatureContract(input) {
    const PUBLIC_KEY_LENGTH = 33;
    const script = buffer_1.Buffer.from(input.toString(), "hex");
    return !(script.length != 40 ||
        script[0] != OpCode_1.OpCode.PUSHDATA1 ||
        script[1] != PUBLIC_KEY_LENGTH ||
        script[35] != OpCode_1.OpCode.SYSCALL ||
        script.slice(36, 40).toString("hex") !=
            InteropServiceCode_1.InteropServiceCode.SYSTEM_CRYPTO_CHECKSIG);
}
exports.isSignatureContract = isSignatureContract;
/**
 * Check if the format of input matches that of a multi-signature contract
 */
function isMultisigContract(input) {
    const script = buffer_1.Buffer.from(input.toString(), "hex");
    if (script.length < 42) {
        return false;
    }
    let signatureCount, i;
    if (script[0] == OpCode_1.OpCode.PUSHINT8) {
        signatureCount = script[1];
        i = 2;
    }
    else if (script[0] == OpCode_1.OpCode.PUSHINT16) {
        signatureCount = script.readUInt16LE(1);
        i = 3;
    }
    else if (script[0] <= OpCode_1.OpCode.PUSH1 || script[0] >= OpCode_1.OpCode.PUSH16) {
        signatureCount = script[0] - OpCode_1.OpCode.PUSH0;
        i = 1;
    }
    else {
        return false;
    }
    if (signatureCount < 1 || signatureCount > 1024) {
        return false;
    }
    let publicKeyCount = 0;
    while (script[i] == OpCode_1.OpCode.PUSHDATA1) {
        if (script.length <= i + 35) {
            return false;
        }
        if (script[i + 1] != 33) {
            return false;
        }
        i += 35;
        publicKeyCount += 1;
    }
    if (publicKeyCount < signatureCount || publicKeyCount > 1024) {
        return false;
    }
    const value = script[i];
    if (value == OpCode_1.OpCode.PUSHINT8) {
        if (script.length <= i + 1 || publicKeyCount != script[i + 1]) {
            return false;
        }
        i += 2;
    }
    else if (value == OpCode_1.OpCode.PUSHINT16) {
        if (script.length < i + 3 || publicKeyCount != script.readUInt16LE(i + 1)) {
            return false;
        }
        i += 3;
    }
    else if (OpCode_1.OpCode.PUSH1 <= value && value <= OpCode_1.OpCode.PUSH16) {
        if (publicKeyCount != value - OpCode_1.OpCode.PUSH0) {
            return false;
        }
        i += 1;
    }
    else {
        return false;
    }
    if (script.length != i + 5 || script[i + 1] != OpCode_1.OpCode.SYSCALL) {
        return false;
    }
    i += 2;
    if (script.slice(i, i + 4).toString("hex") !=
        InteropServiceCode_1.InteropServiceCode.SYSTEM_CRYPTO_CHECKMULTISIG) {
        return false;
    }
    return true;
}
exports.isMultisigContract = isMultisigContract;
function getNativeContractHash(contractName) {
    const innerScript = new ScriptBuilder_1.ScriptBuilder()
        .emitString(contractName)
        .emitSysCall(InteropServiceCode_1.InteropServiceCode.SYSTEM_CONTRACT_CALLNATIVE)
        .build();
    const script = new ScriptBuilder_1.ScriptBuilder()
        .emit(OpCode_1.OpCode.ABORT)
        .emitContractParam(ContractParam_1.ContractParam.hash160("0".repeat(40)))
        .emitHexString(innerScript)
        .build();
    return (0, u_1.reverseHex)((0, u_1.hash160)(script));
}
exports.getNativeContractHash = getNativeContractHash;
