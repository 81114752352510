"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logging = exports.tx = exports.u = exports.CONST = exports.wallet = exports.rpc = exports.sc = exports.api = exports.experimental = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const api = __importStar(require("@cityofzion/neon-api"));
exports.api = api;
const neonCore = __importStar(require("@cityofzion/neon-core"));
const experimental = __importStar(require("./experimental"));
exports.experimental = experimental;
const { sc, rpc, wallet, CONST, u, tx, logging } = neonCore;
exports.sc = sc;
exports.rpc = rpc;
exports.wallet = wallet;
exports.CONST = CONST;
exports.u = u;
exports.tx = tx;
exports.logging = logging;
/**
 * Semantic path for creation of a resource.
 */
const create = {
    account: (k) => new wallet.Account(k),
    privateKey: wallet.generatePrivateKey,
    signature: wallet.generateSignature,
    wallet: (k) => new wallet.Wallet(k),
    contractParam: (type, value) => sc.ContractParam.fromJson({ type, value }),
    script: sc.createScript,
    scriptBuilder: () => new sc.ScriptBuilder(),
    rpcClient: (net) => new rpc.RPCClient(net),
    query: (req) => new rpc.Query(req),
    network: (net) => new rpc.Network(net),
    stringStream: (str) => new u.StringStream(str),
};
/**
 * Semantic path for verification of a type.
 */
const is = {
    address: wallet.isAddress,
    publicKey: wallet.isPublicKey,
    encryptedKey: wallet.isNEP2,
    privateKey: wallet.isPrivateKey,
    wif: wallet.isWIF,
    scriptHash: wallet.isScriptHash,
};
/**
 * Semantic path for deserialization of object.
 */
const deserialize = {
    attribute: tx.TransactionAttribute.deserialize,
    script: tx.Witness.deserialize,
    tx: tx.Transaction.deserialize,
};
/**
 * Semantic path for signing using private key.
 */
const sign = {
    hex: wallet.sign,
    message: (msg, privateKey) => {
        const hex = u.str2hexstring(msg);
        return wallet.sign(hex, privateKey);
    },
};
/**
 * Semantic path for verifying signatures using public key.
 */
const verify = {
    hex: wallet.verify,
    message: (msg, sig, publicKey) => {
        const hex = u.str2hexstring(msg);
        return wallet.verify(hex, sig, publicKey);
    },
};
exports.default = {
    create,
    deserialize,
    is,
    sign,
    verify,
    encrypt: {
        privateKey: wallet.encrypt,
    },
    decrypt: {
        privateKey: wallet.decrypt,
    },
    u,
    CONST,
    experimental,
};
