"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Query = void 0;
const consts_1 = require("../consts");
const tx_1 = require("../tx");
const sc_1 = require("../sc");
const u_1 = require("../u");
const lodash_1 = require("lodash");
function transformInputTransaction(tx) {
    return tx instanceof tx_1.Transaction
        ? u_1.HexString.fromHex(tx.serialize(true)).toBase64()
        : tx instanceof u_1.HexString
            ? tx.toBase64()
            : tx;
}
/**
 * Type guard for narrowing down to an object.
 * @returns
 */
function isJsonRpcParamRecord(i) {
    return i !== undefined && i !== null && typeof i === "object";
}
/**
 * A Query object helps us to construct and record requests for the Neo node RPC. For each RPC endpoint, the equivalent static method is camelcased. Each Query object can only be used once.
 *
 * @example
 * const q1 = Query.getBestBlockHash();
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Query {
    constructor(req) {
        var _a, _b, _c;
        this.id = (_a = req === null || req === void 0 ? void 0 : req.id) !== null && _a !== void 0 ? _a : consts_1.DEFAULT_REQ.id;
        this.method = (_b = req === null || req === void 0 ? void 0 : req.method) !== null && _b !== void 0 ? _b : consts_1.DEFAULT_REQ.method;
        this.params = ((_c = req === null || req === void 0 ? void 0 : req.params) !== null && _c !== void 0 ? _c : []);
    }
    /**
     * Query returning the network fee required for a given transaction.
     */
    static calculateNetworkFee(tx) {
        const base64Tx = transformInputTransaction(tx);
        return new Query({ method: "calculatenetworkfee", params: [base64Tx] });
    }
    /**
     * This Query returns the hash of the highest block.
     */
    static getBestBlockHash() {
        return new Query({
            method: "getbestblockhash",
        });
    }
    /**
     * Query returning the application log.
     */
    static getApplicationLog(hash) {
        return new Query({
            method: "getapplicationlog",
            params: [hash],
        });
    }
    static getBlock(indexOrHash, verbose = 0) {
        return new Query({
            method: "getblock",
            params: [indexOrHash, verbose],
        });
    }
    /**
     * This Query returns the current block height.
     */
    static getBlockCount() {
        return new Query({
            method: "getblockcount",
            params: [],
        });
    }
    /**
     * This Query returns the hash of a specific block.
     * @param index - height of block.
     */
    static getBlockHash(index) {
        return new Query({
            method: "getblockhash",
            params: [index],
        });
    }
    static getBlockHeader(indexOrHash, verbose = 0) {
        return new Query({
            method: "getblockheader",
            params: [indexOrHash, verbose],
        });
    }
    /**
     * This Query returns the list of public keys in the current committee.
     */
    static getCommittee() {
        return new Query({
            method: "getcommittee",
        });
    }
    /**
     * This Query returns the number of other nodes that this node is connected to.
     */
    static getConnectionCount() {
        return new Query({
            method: "getconnectioncount",
        });
    }
    /**
     * This Query returns information about the smart contract registered at the specific hash.
     * @param scriptHash - hash of contract
     */
    static getContractState(scriptHash) {
        return new Query({
            method: "getcontractstate",
            params: [scriptHash],
        });
    }
    /**
     * This Query returns all the native contracts state.
     */
    static getNativeContracts() {
        return new Query({
            method: "getnativecontracts",
        });
    }
    static getNep11Balances(accountIdentifier) {
        return new Query({
            method: "getnep11balances",
            params: [accountIdentifier],
        });
    }
    static getNep11Properties(contractHash, tokenId) {
        return new Query({
            method: "getnep11properties",
            params: [contractHash, tokenId],
        });
    }
    static getNep11Transfers(accountIdentifer, startTime, endTime) {
        const params = [accountIdentifer];
        if (startTime)
            params.push(startTime);
        if (endTime)
            params.push(endTime);
        return new Query({
            method: "getnep17transfers",
            params,
        });
    }
    /**
     * Query returning the Nep17 transfer history of an account. Defaults of 1 week of history.
     * @param accountIdentifer - address or scriptHash of account
     * @param startTime - millisecond Unix timestamp
     * @param endTime - millisecond Unix timestamp
     */
    static getNep17Transfers(accountIdentifer, startTime, endTime) {
        const params = [accountIdentifer];
        if (startTime)
            params.push(startTime);
        if (endTime)
            params.push(endTime);
        return new Query({
            method: "getnep17transfers",
            params,
        });
    }
    /**
     * Query returning the Nep17 balance of an account.
     * @param accountIdentifer - address or scriptHash of account
     */
    static getNep17Balances(accountIdentifer) {
        return new Query({
            method: "getnep17balances",
            params: [accountIdentifer],
        });
    }
    /**
     * This Query returns the list of nodes that this node is connected to.
     */
    static getPeers() {
        return new Query({
            method: "getpeers",
        });
    }
    static getRawMemPool(shouldGetUnverified = 0) {
        return new Query({
            method: "getrawmempool",
            params: [shouldGetUnverified],
        });
    }
    static getRawTransaction(txid, verbose = 0) {
        return new Query({
            method: "getrawtransaction",
            params: [txid, verbose],
        });
    }
    /**
     * This Query returns the value stored at the specific key under a specific contract in base64 format.
     * @param scriptHash - hash of contract.
     * @param key - the storage key in as hex string
     */
    static getStorage(scriptHash, key) {
        return new Query({
            method: "getstorage",
            params: [scriptHash, u_1.HexString.fromHex(key).toBase64()],
        });
    }
    /**
     * This Query returns the block index in which the transaction is found.
     * @param txid - hash of the specific transaction.
     */
    static getTransactionHeight(txid) {
        return new Query({
            method: "gettransactionheight",
            params: [txid],
        });
    }
    /**
     * Gets the list of candidates available for voting.
     * @returns list of validators
     */
    static getNextBlockValidators() {
        return new Query({
            method: "getnextblockvalidators",
        });
    }
    /**
     * Returns the node version.
     */
    static getVersion() {
        return new Query({
            method: "getversion",
        });
    }
    /**
     * Invoke the verification portion of a contract.
     * @param scriptHash - hash of contract to test
     * @param args - arguments to pass
     * @param signers - Signers to be included in transaction
     */
    static invokeContractVerify(scriptHash, args = [], signers = []) {
        return new Query({
            method: "invokecontractverify",
            params: [
                scriptHash,
                args.map((a) => (a instanceof sc_1.ContractParam ? a.toJson() : a)),
                signers.map((s) => (s instanceof tx_1.Signer ? s.toJson() : s)),
            ],
        });
    }
    /**
     * This Query invokes the VM to run the specific contract with the provided operation and params. Do note that this function only suits contracts with a Main(string, args[]) entry method.
     * @param scriptHash - hash of contract to test.
     * @param operation - name of operation to call (first argument)
     * @param params - parameters to pass (second argument). ContractParam objects will be exported to JSON format.
     * @param signers - scripthashes of witnesses that should sign the transaction containing this script. Signers will be exported to JSON format.
     */
    static invokeFunction(scriptHash, operation, params = [], signers = []) {
        return new Query({
            method: "invokefunction",
            params: [
                scriptHash,
                operation,
                params.map((p) => (p instanceof sc_1.ContractParam ? p.toJson() : p)),
                signers.map((s) => (s instanceof tx_1.Signer ? s.toJson() : s)),
            ],
        });
    }
    /**
     * This Query runs the specific script through the VM.
     * @param script - base64-encoded string.
     * @param signers - scripthashes of witnesses that should sign the transaction containing this script.
     */
    static invokeScript(script, signers = []) {
        return new Query({
            method: "invokescript",
            params: [
                script instanceof u_1.HexString ? script.toBase64() : script,
                signers.map((s) => (s instanceof tx_1.Signer ? s.toJson() : s)),
            ],
        });
    }
    /**
     * This Query returns a list of plugins loaded by the node.
     */
    static listPlugins() {
        return new Query({
            method: "listplugins",
            params: [],
        });
    }
    /**
     * This Query transmits the specific transaction to the node. On success, the node will return the transaction hash.
     * @param transaction - transaction as a Transaction object or base64 hexstring.
     */
    static sendRawTransaction(transaction) {
        const base64Tx = transformInputTransaction(transaction);
        return new Query({
            method: "sendrawtransaction",
            params: [base64Tx],
        });
    }
    /**
     * This Query submits a block for processing.
     * @param block - a serialized block
     */
    static submitBlock(block) {
        return new Query({
            method: "submitblock",
            params: [block],
        });
    }
    /**
     * This Query submits an address for validation.
     * @param addr - address to validate.
     */
    static validateAddress(addr) {
        return new Query({
            method: "validateaddress",
            params: [addr],
        });
    }
    /**
     * This Query returns the available unclaimed bonus GAS for a NEO address
     * @param addr - a NEO address
     */
    static getUnclaimedGas(addr) {
        return new Query({
            method: "getunclaimedgas",
            params: [addr],
        });
    }
    get [Symbol.toStringTag]() {
        return "Query";
    }
    export() {
        return {
            params: this.params,
            jsonrpc: "2.0",
            id: this.id,
            method: this.method,
        };
    }
    equals(other) {
        if (this.id !== other.id && this.method !== other.method) {
            return false;
        }
        if (Array.isArray(this.params) && Array.isArray(other.params)) {
            const otherParams = other.params;
            return (this.params.length === otherParams.length &&
                this.params.every((val, ind) => otherParams[ind] === val));
        }
        if (isJsonRpcParamRecord(this.params) &&
            isJsonRpcParamRecord(other.params)) {
            return (0, lodash_1.isEqual)(this.params, other.params);
        }
        return false;
    }
}
exports.Query = Query;
exports.default = Query;
