import React from "react"

import AccountContext from "../../contexts/AccountContext"
import { useNavigate, useLocation } from 'react-router-dom';
// import WalletConnectContext from "../../contexts/WalletConnectContext";
import { useSearchParams } from "react-router-dom";
import Header from "../Components/Header";
import WithdrawAssets from "../Components/WithdrawAssets";
import { Link } from "react-router-dom";
import QRCodeScanner from "../Components/QRCodeScanner";
import ConnectWalletConnect from "../Components/ConnectWalletConnect";
import Loading from "../Components/Loading";
import Global from "../../Global"
import { Chest, types } from '@cityofzion/props'
import BluePuppet from "./../../assets/blue_key_in_wallet.png"
import GreenPuppet from "./../../assets/green_key_in_wallet.png"
import GoldPuppet from "./../../assets/gold_key_in_wallet.png"
import BlacksmithKeyInWallet from "./../../assets/blacksmith_key_in_wallet.png"
import ViewPrivateKey from "../Components/ViewPrivateKey";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {useWalletConnect} from "../../contexts/WalletConnectContext";
import {DEFAULT_NAMESPACES} from "../../constants";

export default function Wallet(props) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const openNext = searchParams.get("open")

    const { account, chestKey, setChestKey, puppetTokenId, setPuppetTokenId, goldPuppetTokenId, setGoldPuppetTokenId, balances, setBalances, nep11Balances, setNep11Balances, isEligibleForGoldChest, setIsEligibleForGoldChest } = React.useContext(AccountContext)

    // const { wcClient, isConnected, chestMetadata, session, proposal } = React.useContext(WalletConnectContext)

    const [requestConnectPresented, setRequestConnectPresented] = React.useState(false)
    const [scannerIsPresented, setScannerIsPresented] = React.useState(false)
    const [openingChest, setOpeningChest] = React.useState(false)
    const [connecting, setConnecting] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [isWithdrawAssetsPresented, setIsWithdrawAssetsPresented] = React.useState(false)
    const [isViewPrivateKeyPresented, setIsViewPrivateKeyPresented] = React.useState(false)
    const SupportedAssets = Global.Assets;

    const walletConnectCtx = useWalletConnect()
    const isConnected = walletConnectCtx.sessions.length > 0
    console.log(walletConnectCtx.sessions)
    const chestMetadata = null// walletConnectCtx.sessions.length > 0 ? walletConnectCtx.sessions[0].proposer.metadata : null


    const loadAssets = async () => {
        const fetchData = url => fetch(url).then(r => r.json()); // 1
        const nep11AssetsUrl = `https://api.ghostmarket.io/api/v1/assets?chain=n3&owner=${account.address}`
        const balancesUrl = `https://dora.coz.io/api/v1/neo3/mainnet/balance/${account.address}`

        const [nep11Assets, assets] = await Promise.all([
            fetchData(nep11AssetsUrl),
            fetchData(balancesUrl)
        ]);
        setNep11Balances(nep11Assets)
        setBalances(assets)
    }

    React.useEffect(() => {
        if (account !== null) {
            console.log("/wallet loadAssets of", account.address)
            loadAssets()
        }
    }, [account])

    //try to connect gold 
   React.useEffect(() => {
    if (walletConnectCtx.sessionProposals.length === 0) return;

    if (walletConnectCtx.sessions.length > 0) return;

    setRequestConnectPresented(true)
    setConnecting(false)
  }, [walletConnectCtx.sessionProposals, walletConnectCtx.sessions])


    //user connected with gold chest
    React.useEffect(() => {
        if (walletConnectCtx.sessions.length > 0) {
          console.log("when a user approved a connection")
          navigate("/connected", { replace: true })
        };
      }, [walletConnectCtx.sessions])

    React.useEffect(() => {

        if (nep11Balances !== null) {
            //if user has nep11 assets we then loop through it to find if user has a gold key
            nep11Balances.assets && nep11Balances.assets.map((a) => {
                const metaData = a.nft.nft_metadata
                const description = metaData.description;

                let findGoldKey = metaData.attributes.filter(attribute => { return attribute.name === "traits.color" && attribute.value === "gold" })

                if (findGoldKey.length > 0) {

                    var regex = /\#(.*?)\./
                    var matched = regex.exec(description);
                    const tokenId = matched[1]
                    console.log("gold token id = ", tokenId)
                    setGoldPuppetTokenId(tokenId)

                    //try to check eligible again here
                    checkIsEligibleForGoldChest(tokenId)
                }

            })
        }

    }, [nep11Balances, balances])

    const checkIsEligibleForGoldChest = async (tokenId) => {
        const targetNetwork = types.NetworkOption.MainNet
        const chestId = "\u0006"
        const puppetId = tokenId

        const chest = await new Chest({
            network: targetNetwork
        })
        await chest.init()

        const eligible = await chest.isPuppetEligible(chestId, puppetId)
        console.log("eligible for gold chest ", eligible)
        setIsEligibleForGoldChest(eligible)
    }




    React.useEffect(() => {
        if (openNext == "scan") {
            searchParams.delete("open")
            setScannerIsPresented(true);
        }
    }, [openNext])


    if (account === null) {
        return (
            <div>no account</div>
        )
    }

    const onDismissWithDrawAssets = () => {
        setIsWithdrawAssetsPresented(false)
    }

    const onDismissViewPrivateKey = () => {
        setIsViewPrivateKeyPresented(false)
    }

    //When a user scanned a QR code we then try to pair with a given WalletConnect URI
    const onScanned = async (scannedString) => {
        setScannerIsPresented(false)
        let uri = scannedString
        //we need to pair first and then show the dialog
        setConnecting(true)
        console.log("pairing", uri)
        // try {
        //   console.log("wcClient",wcClient)
        //   wcClient.pair({ uri: uri });
        // } catch (error) {
        //   console.log("error pairing", error)
        // }
        try {
          await walletConnectCtx.onURI(uri)
        } catch (e) {
          setConnecting(false)
          alert(e)
        }
      }

    const onApproveWalletConnect = () => {
        setRequestConnectPresented(false)
        let approved = true;
        const firstProposal = walletConnectCtx.sessionProposals[0]
        handleSessionUserApproval(approved, firstProposal);
    }

    const onCancelWalletConnect = () => {
        setRequestConnectPresented(false)
        let approved = false;
        const firstProposal = walletConnectCtx.sessionProposals[0]
        handleSessionUserApproval(approved, firstProposal);
    }

    const handleSessionUserApproval = async (approved, proposal) => {
        if (approved) {
            await walletConnectCtx.approveSession(proposal, [{
              address: account.address ?? '',
              chain: "neo3:mainnet"
            }], DEFAULT_NAMESPACES)  
          } else {
            walletConnectCtx.rejectSession(proposal)
          }
    }


    const puppetKeyImage = (puppet) => {

        var trade = puppet.nft_metadata.attributes.filter((attribute) => { return attribute.name === "traits.trade" })
        if (trade.length > 0) {
            switch (trade[0].value) {
                case "blacksmith": return BlacksmithKeyInWallet
                default: return BlacksmithKeyInWallet
            }
        }
        
        switch (puppet.nft_metadata.attributes.filter((attribute) => { return attribute.name === "traits.color" })[0].value) {
            case "blue": return BluePuppet
            case "green": return GreenPuppet
            case "gold": return GoldPuppet
            default: return BluePuppet
        }
        return BluePuppet;
    }

    return (
        <div className="absolute inset-0">
            {
                connecting === true ?
                    <Loading></Loading>
                    :
                    null
            }


            {
                isWithdrawAssetsPresented ?
                    <WithdrawAssets isOpen={isWithdrawAssetsPresented} onDismiss={onDismissWithDrawAssets}></WithdrawAssets>
                    : null
            }

            {
                isViewPrivateKeyPresented ?

                    <Transition show={isViewPrivateKeyPresented} as={Fragment}>
                        <Dialog onClose={() => onDismissViewPrivateKey()}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/30" />
                            </Transition.Child>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel>

                                    <ViewPrivateKey isOpen={isViewPrivateKeyPresented} onDismiss={onDismissViewPrivateKey} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </Dialog>
                    </Transition>
                    : null
            }

            <div className="p-3 container mx-auto relative flex flex-col h-full ">
                <div className="flex items-center gap-4">

                    <button onClick={(e) => { setIsViewPrivateKeyPresented(!isViewPrivateKeyPresented) }} className="text-sm flex items-center gap-2 py-1 px-3 text-white rounded-full font-bold uppercase bg-secondary">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                            <path fillRule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clipRule="evenodd" />
                        </svg>

                        <span>Export</span>
                    </button>

                    {
                        isConnected === true ?
                            <div className={`flex items-center text-primary}`}>

                                <svg className="fill-current mr-1" width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.72 6C21.72 3.948 20.052 2.28 18 2.28H13.2V0H18C21.312 0 24 2.688 24 6C24 9.312 21.312 12 18 12H13.2V9.72H18C20.052 9.72 21.72 8.052 21.72 6ZM16.8 7.2H7.2V4.8H16.8V7.2ZM6 0H10.8V2.28H6C3.948 2.28 2.28 3.948 2.28 6C2.28 8.052 3.948 9.72 6 9.72H10.8V12H6C2.688 12 0 9.312 0 6C0 2.688 2.688 0 6 0Z" />
                                </svg>


                                <span className="font-semibold text-sm uppercase">CONNECTED</span>
                            </div>
                            : <div></div>
                    }
                    <div className="ml-auto">
                        <button onClick={(e) => { setIsWithdrawAssetsPresented(!isWithdrawAssetsPresented) }} className="text-sm flex items-center p-1 px-3 text-white rounded-full font-bold uppercase bg-primary">
                            <svg className="mr-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5.29412H8.57143V0H3.42857V5.29412H0L6 11.4706L12 5.29412ZM0 13.2353V15H12V13.2353H0Z" fill="white" />
                            </svg>
                            <span>Withdraw</span>
                        </button>
                    </div>
                </div>

                <div className="mt-6">
                    <Header></Header>
                </div>

                <div className="mt-6 tex-center flex flex-col items-center justify-center">
                    <p className="font-semibold mb-2 uppercase">YOUR PAPER WALLET</p>
                    <div className="font-bold text-sm text-center p-2 px-4 bg-gray-100 rounded-full">{account.address}</div>
                </div>
                {
                    goldPuppetTokenId !== null && isEligibleForGoldChest === true ? //if we found gold puppet in a user wallet and it's still eligible
                        <div className="mt-3">
                            <div className="flex items-center justify-center rounded-lg w-full overflow-hidden shadow-xl bg-slate-700 h-full">
                                <div className="flex-none bg-slate-900 w-24 h-full flex items-center justify-center">
                                    <svg width="41" height="47" viewBox="0 0 41 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.47632 6.73071L11.0741 14.3285C12.0557 15.3101 13.3871 15.8616 14.7754 15.8616H26.7822C28.1704 15.8616 29.5018 15.3101 30.4834 14.3285L38.0812 6.73071" stroke="#F9DE02" strokeWidth="2.62417" strokeLinecap="square" />
                                        <path d="M25.0105 20.1238V25.1239C25.0105 27.3196 25.7009 29.4598 26.9841 31.2416L34.9192 42.2606" stroke="#F9DE02" strokeWidth="2.62417" strokeLinecap="square" />
                                        <path d="M16.5752 20.1238V25.1239C16.5752 27.3196 15.8848 29.4598 14.6016 31.2416L6.66645 42.2606" stroke="#F9DE02" strokeWidth="2.62417" strokeLinecap="square" />
                                        <path d="M5.74938 15.8614H1.51489" stroke="#F9DE02" strokeWidth="2.62417" strokeLinecap="square" />
                                        <path d="M39.6861 15.8614H35.4517" stroke="#F9DE02" strokeWidth="2.62417" strokeLinecap="square" />
                                        <path d="M17.317 37.4429V45" stroke="#F9DE02" strokeWidth="2.62417" strokeLinecap="square" />
                                        <path d="M23.7377 37.4429V45" stroke="#F9DE02" strokeWidth="2.62417" strokeLinecap="square" />
                                        <circle cx="20.7286" cy="6.39732" r="4.19119" stroke="#F9DE02" strokeWidth="2.62417" strokeLinecap="square" />
                                    </svg>

                                </div>
                                <div className="w-full p-3">
                                    <p className="font-bold text-yellow-400">GOLD PUPPET KEY</p>
                                    <p className="text-white font-semibold text-sm">
                                        You haven’t tried you luck with your Gold Key.
                                    </p>
                                    <div className="flex items-center">
                                        <button onClick={(e) => { setScannerIsPresented(true); }} className="text-yellow-400 font-semibold ml-auto">Scan box now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }


                <div className="mt-6 tex-center flex flex-col items-center justify-center w-full">
                    <p className="font-semibold mb-2 uppercase">ASSETS</p>
                    <div className="flex flex-col gap-2 w-full">
                        {
                            //so we don't include Puppet in the balance here because we have another section to show nep11
                            balances && balances.filter((a) => { return (SupportedAssets[a.asset].protocol === "nep17" && a.asset !== "0x76a8f8a7a901b29a33013b469949f4b08db15756") }).map((a) => (
                                <Link key={a.asset} to={`/assets/${a.asset}`}>
                                    <div className="flex items-center justify-center rounded-lg w-full overflow-hidden shadow-xl">
                                        <div className="w-16 h-16 bg-slate-900 flex items-center justify-center">
                                            {
                                                SupportedAssets[a.asset] ?
                                                    <img src={SupportedAssets[a.asset].icon_url} />
                                                    : null
                                            }
                                        </div>
                                        <div className="pl-3">
                                            <p className="font-bold leading-none">{a.symbol}</p>
                                            <p>{a.balance}</p>
                                        </div>
                                        <div className="ml-auto pr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-primary h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }

                    </div>
                    <div className="flex flex-col gap-2 w-full mt-2">
                        {
                            (nep11Balances && nep11Balances.assets) && nep11Balances.assets.map((a) => (
                                <div key={a.nft.token_id}>
                                    {
                                        //update this line to include future puppet key to show custom image
                                        a.nft.nft_metadata.description !== undefined && (a.nft.nft_metadata.description.includes("Puppets(3)") || a.nft.nft_metadata.description.includes("Puppets(5)") || a.nft.nft_metadata.description.includes("Blacksmiths(9)")) ?
                                            <Link to={`/assets/${a.nft.contract}?token_id=${a.nft.token_id}`}>
                                                <div className="flex items-center justify-center rounded-lg w-full overflow-hidden shadow-xl">
                                                    <div className="flex-none w-16 h-16 bg-slate-900  flex items-center justify-center">
                                                        {
                                                            <img src={puppetKeyImage(a.nft)} />
                                                        }
                                                    </div>
                                                    <div className="pl-3 truncate overflow-ellipsis">
                                                        {
                                                            a.nft.nft_metadata.description.includes("Puppets(3)") || a.nft.nft_metadata.description.includes("Puppets(5)") ? 
                                                            <>
                                                            <p className="inline-block text-xs font-bold leading-none px-3 py-1 bg-gray-200 rounded-full">WINNER</p>
                                                        <p className="mt-1 font-bold leading-none capitalize">{a.nft.nft_metadata.attributes.filter((attribute) => { return attribute.name === "traits.color" })[0].value} Puppet Key</p>
                                                            </>    
                                                            :
                                                            <>
                                                            <p className="inline-block text-xs font-bold leading-none px-3 py-1 bg-gray-200 rounded-full">WINNER</p>
                                                        <p className="mt-1 font-bold leading-none capitalize">{a.nft.nft_metadata.attributes.filter((attribute) => { return attribute.name === "traits.trade" })[0].value} Puppet Key</p></>
                                                        }
                                                        
                                                    </div>
                                                    <div className="ml-auto pr-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-primary h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                                        </svg>
                                                    </div>

                                                </div>
                                            </Link>
                                            :
                                            <Link to={`/assets/${a.nft.contract}?token_id=${a.nft.token_id}`}>
                                                <div className="flex items-center justify-center rounded-lg w-full overflow-hidden shadow-xl">
                                                    <div className="flex-none w-16 h-16 bg-slate-900  flex items-center justify-center">
                                                        {
                                                            SupportedAssets[a.nft.contract] ?
                                                                <img src={SupportedAssets[a.nft.contract].icon_url} />
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="pl-3 truncate overflow-ellipsis">
                                                        <p className="font-bold leading-none">{a.nft.symbol}</p>
                                                        <p className="line-clamp-1 truncate overflow-ellipsis">{a.nft.nft_metadata.description}</p>
                                                    </div>
                                                    <div className="ml-auto pr-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-primary h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                                        </svg>
                                                    </div>

                                                </div>
                                            </Link>
                                    }
                                </div>
                            ))
                        }

                    </div>
                </div>

                <div className="mt-auto border-t py-3">
                    <a href="https://neo.org" target="_blank" className="flex items-center justify-center rounded-lg w-full overflow-hidden shadow-xl border border-gray-100">
                        <div className="w-16 h-16 p-1 flex items-center justify-center">
                            <svg width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 7.2519V32.3053L16.2863 38.1396V12.8679L33.8517 6.4095L17.9087 0.699951L0 7.2519Z" fill="#00E599" />
                                <path d="M17.5967 13.8038V27.3133L33.8517 33.1164V7.78223L17.5967 13.8038Z" fill="#00AF92" />
                            </svg>

                        </div>
                        <div className="pl-3">
                            <p className="font-bold leading-none">Get to know Neo</p>
                            <p>Find out more at Neo.org</p>
                        </div>
                        <div className="ml-auto pr-3">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11.88 9.54L6.92 14.5L5.51 13.09L10.47 8.13L8.34 6L13.99 6.01L14 11.66L11.88 9.54Z" fill="#00E599" />
                            </svg>

                        </div>
                    </a>
                </div>

            </div>

            {
                requestConnectPresented === true ?
                    <ConnectWalletConnect chestMetadata={chestMetadata} onApprove={onApproveWalletConnect} onCancel={onCancelWalletConnect}></ConnectWalletConnect>
                    : null
            }
            {
                scannerIsPresented === true ?
                    <QRCodeScanner account={account} onScanned={onScanned} onClose={(e) => setScannerIsPresented(false)} />
                    : null
            }

        </div>
    )
}