import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import {WalletConnectContextProvider} from "./contexts/WalletConnectContext";
const root = ReactDOM.createRoot(document.getElementById('root'));

const walletWcOptions = {
  logger: "debug",
  projectId: "be7acb144a1e054328a3d8229c9f3179",
  relayUrl: "wss://relay.walletconnect.com",
  metadata: {
    name: "Wallet for Chest App",
    description: "Wallet for Chest App",
    url: "https://neo.org",
    icons: ["https://walletconnect.com/walletconnect-logo.png"],
  }
};


root.render(
  <WalletConnectContextProvider  options={walletWcOptions}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </WalletConnectContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
