"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createScript = void 0;
const ScriptBuilder_1 = require("./ScriptBuilder");
/**
 * Translates a ScriptIntent / array of ScriptIntents into hexstring.
 * @param scripts - ContractCall or hexstrings.
 */
function createScript(...scripts) {
    const sb = new ScriptBuilder_1.ScriptBuilder();
    for (const script of scripts) {
        if (typeof script === "string") {
            sb.str += script;
            continue;
        }
        const contractCall = script;
        if (!contractCall.scriptHash) {
            throw new Error("No scriptHash found!");
        }
        if (!contractCall.operation) {
            throw new Error("No operation found!");
        }
        sb.emitContractCall(script);
    }
    return sb.build();
}
exports.createScript = createScript;
