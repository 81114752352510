"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractEventDefiniton = void 0;
const internal_1 = require("../../internal");
const ContractParam_1 = require("../ContractParam");
class ContractEventDefiniton {
    constructor(obj) {
        const { name = "", parameters = [] } = obj;
        this.name = name;
        this.parameters = [...parameters];
    }
    static fromJson(json) {
        return new ContractEventDefiniton({
            name: json.name,
            parameters: json.parameters.map((p) => ({
                name: p.name,
                type: (0, internal_1.parseEnum)(p.type, ContractParam_1.ContractParamType),
            })),
        });
    }
    toJson() {
        return {
            name: this.name,
            parameters: this.parameters.map((p) => ({
                name: p.name,
                type: ContractParam_1.ContractParamType[p.type],
            })),
        };
    }
    export() {
        return {
            name: this.name,
            parameters: [...this.parameters],
        };
    }
}
exports.ContractEventDefiniton = ContractEventDefiniton;
