"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSignaturesFromInvocationScript = exports.getSigningThresholdFromVerificationScript = exports.getPublicKeysFromVerificationScript = exports.constructMultiSigVerificationScript = void 0;
const sc_1 = require("../sc");
const verify_1 = require("./verify");
/**
 * Constructs the script for a multi-sig account.
 * @param signingThreshold - number of keys required for signing. Must be smaller or equal to the number of keys provided.
 * @param keys - public keys of all keys involved. Ordering matters.
 */
function constructMultiSigVerificationScript(signingThreshold, keys) {
    if (signingThreshold <= 0) {
        throw new Error("signingThreshold must be bigger than zero.");
    }
    if (signingThreshold > keys.length) {
        throw new Error("signingThreshold must be smaller than or equal to number of keys");
    }
    const sb = new sc_1.ScriptBuilder();
    sb.emitPush(signingThreshold);
    keys.forEach((k) => {
        if (!(0, verify_1.isPublicKey)(k, true)) {
            throw new Error(`${k} is not a valid encoded public key`);
        }
        sb.emitPublicKey(k);
    });
    return sb
        .emitPush(keys.length)
        .emitSysCall(sc_1.InteropServiceCode.SYSTEM_CRYPTO_CHECKMULTISIG)
        .build();
}
exports.constructMultiSigVerificationScript = constructMultiSigVerificationScript;
/**
 * Returns the list of public keys found in the verification script.
 * @param verificationScript - verification Script of an Account.
 */
function getPublicKeysFromVerificationScript(verificationScript) {
    const operations = sc_1.OpToken.fromScript(verificationScript);
    return operations.filter(looksLikePublicKey).map((t) => t.params);
}
exports.getPublicKeysFromVerificationScript = getPublicKeysFromVerificationScript;
function looksLikePublicKey(token) {
    return (token.code === sc_1.OpCode.PUSHDATA1 &&
        !!token.params &&
        token.params.length === 66);
}
/**
 * Returns the number of signatures required for signing for a verification Script.
 * @param verificationScript - verification script of a multi-sig Account.
 */
function getSigningThresholdFromVerificationScript(verificationScript) {
    const operations = sc_1.OpToken.fromScript(verificationScript);
    return sc_1.OpToken.parseInt(operations[0]);
}
exports.getSigningThresholdFromVerificationScript = getSigningThresholdFromVerificationScript;
/**
 * Extract signatures from invocationScript
 * @param invocationScript - invocationScript of a Witness.
 */
function getSignaturesFromInvocationScript(invocationScript) {
    return sc_1.OpToken.fromScript(invocationScript)
        .filter((token) => token.code === sc_1.OpCode.PUSHDATA1 &&
        !!token.params &&
        token.params.length === 128)
        .map((token) => token.params);
}
exports.getSignaturesFromInvocationScript = getSignaturesFromInvocationScript;
